import { DataFrame } from "danfojs";
import axios from "axios";
import { utils } from "xlsx";

export default {
  methods: {
    saveSelectedRows() {
      const selectedData = this.selectedRows.map(
        (index) => this.duplicateRows[index]
      );
      if (selectedData.length === 0) {
        this.InactiveAssetUploadProgress = -1;
        this.feedbackerror =
          " Duplicate value exists in data, please reset,reupload excel select data to remove from duplicates, then click SAVE";
      } else {
        const rowsToDrop = [];
        let valuesToDrop = selectedData.map((row) => row[0]);
        this.InactiveAssetUploadProgress = 60;
        valuesToDrop.forEach((value) => {
          this.InactiveAssetuploadedData.values.forEach((row, index) => {
            if (row[0] === value) {
              rowsToDrop.push(index);
            }
          });
        });
        rowsToDrop.forEach((rowIndex) => {
          this.InactiveAssetuploadedData.drop({
            index: [rowIndex],
            inplace: true,
          });
        });


        this.create_table_insertdata();
      }
      // Close the modal
      this.showModal = false;
    },
    closeModal() {
      // Close the modal
      this.showModal = false;
      this.duplicateRows = [];
      this.selectedRows = [];
      this.create_table_insertdata();
    },
    check_iot_enable_asset_duplicates(dfs, iot_enable_asset_id_dup, dfFilled) {
      const iotenable_num_indices = [];

      const iot_enable_asset_id_dup_Values =
        dfs[iot_enable_asset_id_dup].values;

      const iot_enable_asset_id_dup_Values_loc =
        iot_enable_asset_id_dup_Values.reduce((acc, value, index) => {
          if (value !== "") {
            acc.push(dfs.iloc({ rows: [index] }).values[0]);
          }
          return acc;
        }, []);

      // Create a new DataFrame from the filtered values
      let iot_enable_asset_id_dup_DataFrame = new DataFrame(
        iot_enable_asset_id_dup_Values_loc,
        {
          columns: dfs.columns,
        }
      );
      const iot_enable_asset_dup_counts = iot_enable_asset_id_dup_DataFrame
        .groupby([iot_enable_asset_id_dup])
        .col([iot_enable_asset_id_dup])
        .count();

      const iot_enable_asset_dup_values =
        iot_enable_asset_dup_counts[iot_enable_asset_id_dup].values;

      const iot_enable_asset_dup_count =
        iot_enable_asset_dup_counts[iot_enable_asset_id_dup + "_count"].values;

      let iot_enable_asset_dup_value_list = iot_enable_asset_dup_count.reduce(
        (acc, value, index) => {
          if (value > 1) {
            acc.push(iot_enable_asset_dup_values[index]);
          }
          return acc;
        },
        []
      );

      const listofvaluedfiotenables = dfFilled[iot_enable_asset_id_dup].values;
      listofvaluedfiotenables.forEach((value, index) => {
        if (iot_enable_asset_dup_value_list.includes(value)) {
          iotenable_num_indices.push(index);
        }
      });
      return iotenable_num_indices;
    },
    check_serial_num_duplicates(dfs, serialnum_dup, dfFilled) {
      const serial_num_indices = [];
      const serialnum_dup_dup_Values = dfs[serialnum_dup].values;

      const iserialnum_dup_dup_Values_Values_loc =
        serialnum_dup_dup_Values.reduce((acc, value, index) => {
          if (value !== "") {
            acc.push(dfs.iloc({ rows: [index] }).values[0]);
          }
          return acc;
        }, []);

      // Create a new DataFrame from the filtered values
      let iserialnum_dup_dup_DataFrame = new DataFrame(
        iserialnum_dup_dup_Values_Values_loc,
        {
          columns: dfs.columns,
        }
      );

      const isserial_num_sl_dup_counts = iserialnum_dup_dup_DataFrame
        .groupby([serialnum_dup])
        .col([serialnum_dup])
        .count();

      const iserialnum_dup_values =
        isserial_num_sl_dup_counts[serialnum_dup].values;

      const iserialnum_dup_values_count =
        isserial_num_sl_dup_counts[serialnum_dup + "_count"].values;

      let iserialnum_dup_value_list = iserialnum_dup_values_count.reduce(
        (acc, value, index) => {
          if (value > 1) {
            acc.push(iserialnum_dup_values[index]);
          }
          return acc;
        },
        []
      );

      const listofvaluedfSerialNum = dfFilled[serialnum_dup].values;
      listofvaluedfSerialNum.forEach((value, index) => {
        if (iserialnum_dup_value_list.includes(value)) {
          serial_num_indices.push(index);
        }
      });

      return serial_num_indices;
    },
    check_imei_num_duplicates(dfs, imei_id_dup, dfFilled) {
      const imei_num_indices = [];
      const imei_id_dup_Values = dfs[imei_id_dup].values;

      const ime_dup_val_loc = imei_id_dup_Values.reduce((acc, value, index) => {
        if (value !== "") {
          acc.push(dfs.iloc({ rows: [index] }).values[0]);
        }
        return acc;
      }, []);

      // Create a new DataFrame from the filtered values
      let imei_dup_DataFrame = new DataFrame(ime_dup_val_loc, {
        columns: dfs.columns,
      });

      const imei_dup_counts = imei_dup_DataFrame
        .groupby([imei_id_dup])
        .col([imei_id_dup])
        .count();

      const imei_dup_values = imei_dup_counts[imei_id_dup].values;

      const imei_dup_values_count =
        imei_dup_counts[imei_id_dup + "_count"].values;

      let imei_dup_value_list = imei_dup_values_count.reduce(
        (acc, value, index) => {
          if (value > 1) {
            acc.push(imei_dup_values[index]);
          }
          return acc;
        },
        []
      );

      const listofvaluedfimei = dfFilled[imei_id_dup].values;
      listofvaluedfimei.forEach((value, index) => {
        if (imei_dup_value_list.includes(value)) {
          imei_num_indices.push(index);
        }
      });
      return imei_num_indices;
    },

    async duplicate_data_modal_exportToExcel() {
      this.duplicatevalueexportloading = true;
      try {
        const table = document.getElementById("duplicatevalu-data-table");
        var opts = {
          raw: true,
        };
        const table_Sheet_Data = utils.table_to_sheet(table, opts);
        const jsonData = utils.sheet_to_json(table_Sheet_Data, { header: 1 });
        let headers = jsonData[0];
        headers.splice(0, 2); // Remove the first and second columns from headers

        // Remove sorting arrows from headers
        headers = headers.map(header => {
          return header.replace(/▲▼/g, '');
        });
        const data = jsonData.slice(1).map(row => {
          // Remove the first and second columns from each data row
          return row.slice(2);
        });



        // Define the request payload
        const payload = {
          headers: headers,
          data: data,
        };

        // Send a POST request to the backend to generate and download the Excel file
        try {
          axios
            .post("https://fmsnodebackenddev-pcalvs64ua-uc.a.run.app/auth/exportfunction/", payload, {
              responseType: "blob", // Receive response as a blob (binary data)
            })
            .then((response) => {
              // Create a blob URL for the response data
              const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });
              const url = window.URL.createObjectURL(blob);

              // Create a link element to trigger the download
              const a = document.createElement("a");
              a.href = url;
              a.download = `${this.table_name_final}.xlsx`;

              // Trigger a click event to initiate the download
              a.click();

              // Clean up the URL object to release resources
              window.URL.revokeObjectURL(url);
              this.duplicatevalueexportloading = false;
              //this.feedbackerror = null;
            })
            .catch((error) => {
              console.error(
                "ERROR - Error while exporting dulicate data Excel file:",
                error
              );
              this.duplicatevalueexportloading = false;
            });
        } catch (error) {
          // Handle error
          console.error(
            "ERROR - Error while exporting dulicate data Excel file:",
            error
          );
          this.duplicatevalueexportloading = false;
        }
      } catch (error) {
        // Handle error
        console.error(
          "ERROR - Error while exporting dulicate data Excel file:",
          error
        );
        this.duplicatevalueexportloading = false;
      }
    },
    sortBy(column) {
      if (column === this.sortColumn) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortOrder = "asc";
        this.sortColumn = column;
      }

      if (this.sortOrder === "asc") {
        this.duplicated_data_rows_user.sortValues(column, { inplace: true });
      } else {
        this.duplicated_data_rows_user.sortValues(column, {
          ascending: false,
          inplace: true,
        });
      }

      this.duplicateRows = this.duplicated_data_rows_user.values;
    },
  },
};
