import { createRouter, createWebHashHistory } from 'vue-router'

import AnalysisHome from '../components/AnalysisPage/Inactiveassetanalysis/InactiveAssetUpload.vue'
import historyData from '../components/AnalysisPage/HistorytableData.vue'
import LoginPage from '../components/UserValidation/LoginPage.vue'
import RegistrationPage from '../components/UserValidation/RegistrationPage.vue'
import gcphealthservice from '../components/GCPMonitoring/gcpServicehealth.vue'
import simcardmanagement from '../components/SimManagement/SimCardLanding.vue'
import assetanalysislanding from '../components/AnalysisPage/AnalysisLandingPage.vue'
const routes = [

      {
        path: '/AnalysisHome/:username',
        name: 'AnalysisHome',
        component: AnalysisHome,
        },

          {
            path: '/historyData/:username',
            name: 'historyData',
            component: historyData,
            },

              {
                path: '/LoginPage',
                name: 'LoginPage',
                component: LoginPage,
                },
                {
                  path: '/RegistrationPage',
                  name: 'RegistrationPage',
                  component: RegistrationPage,
                  },
                  {
                    path: '/gcphealthservice',
                    name: 'gcphealthservice',
                    component: gcphealthservice,
                    },
                    {
                      path: '/simcardmanagement',
                      name: 'simcardmanagement',
                      component: simcardmanagement,
                      },
                      {
                        path: '/assetanalysislanding/:username',
                        name: 'assetanalysislanding',
                        component: assetanalysislanding,
                        },


]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
