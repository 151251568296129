<template>
  <div class="asltabs-container">
    <div class="aslhorizontal-tabs">
      <div
        v-for="(asltab, aslindex) in asltabs"
        :key="aslindex"
        class="asltab-container"
        :class="{ 'aslactive-tab': aslactiveTab === asltab.aslid }"
        @click="aslchangeTab(asltab.aslid)"
      >
        <div class="aslhorizontal-tab">
          {{ asltab.asllabel }}
        </div>
        <div class="asltab-line"></div> <!-- Line after each tab -->
        <div class="asltab-cut"></div>
      </div>
    </div>

    <div v-show="aslactiveTab === 'asltab1'" class="asltab-content" :key="aslactiveTab">
      <inactiveassetanalysis></inactiveassetanalysis>
    </div>
    <div v-show="aslactiveTab === 'asltab2'" class="asltab-content" :key="aslactiveTab">
      <inactiveassethistory></inactiveassethistory>
    </div>
  </div>
</template>

<script>
import inactiveassetanalysis from './Inactiveassetanalysis/InactiveAssetUpload.vue'
import inactiveassethistory from './HistorytableData.vue'
export default {
  data() {
    return {
      aslactiveTab: 'asltab1',
      asltabs: [
        { aslid: 'asltab1', asllabel: 'Asset Analysis' },
        { aslid: 'asltab2', asllabel: 'History' },
      ],
    };
  },
  methods: {
    aslchangeTab(asltab) {
      this.aslactiveTab = asltab;
    },
  },
  components:{
    inactiveassetanalysis,
    inactiveassethistory
  }
};
</script>

<style scoped>
/* Horizontal Tabs Styles */
.aslhorizontal-tabs {
  display: flex;
}

/* Tab Container Styles */
.asltab-container {
  position: relative;
}

/* Horizontal Tab Styles */
.aslhorizontal-tab {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  user-select: none;
  display: inline;
  position: relative;
  z-index: 1; /* Ensure the tab content appears above the cut */
  margin-right: 5px;
}

.aslactive-tab {
  background-color: #497e98;
  color: #fff;
}

/* Tab Line Styles */
.asltab-line {
  height: 2px;
  width: 100%;
  background-color: #ddd; /* Line color */
  position: absolute;
  bottom: 0;
}

/* Tab Cut Styles */
.asltab-cut {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 10px;
  background-color: #fff;
  clip-path: polygon(100% 0, 0 50%, 100% 100%); /* Cut at the right side */
}
</style>
