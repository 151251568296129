<template>
  <div class="tabs-container">
    <div class="horizontal-tabs">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="tab-container"
        :class="{ 'active-tab': activeTab === tab.id }"
        @click="changeTab(tab.id)"
      >
        <div class="horizontal-tab">
          {{ tab.label }}
        </div>
        <div class="tab-line"></div> <!-- Line after each tab -->
        <div class="tab-cut"></div>
      </div>
    </div>

    <div v-show="activeTab === 'tab1'" class="tab-content">
      <SimManagement></SimManagement>
    </div>
    <div v-show="activeTab === 'tab2'" class="tab-content">
      <SimActivation></SimActivation>
    </div>
  </div>
</template>

<script>
import SimManagement from './SimMangement.vue'
import SimActivation from './SimActivation.vue'
export default {
  data() {
    return {
      activeTab: 'tab1',
      tabs: [
        { id: 'tab1', label: 'SIM Management' },
        { id: 'tab2', label: 'SIM Activation' },
      ],
    };
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
    },
  },
  components:{
    SimManagement,
    SimActivation
  }
};
</script>

<style scoped>
/* Horizontal Tabs Styles */
.horizontal-tabs {
  display: flex;
}

/* Tab Container Styles */
.tab-container {
  position: relative;
}

/* Horizontal Tab Styles */
.horizontal-tab {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  user-select: none;
  display: inline;
  position: relative;
  z-index: 1; /* Ensure the tab content appears above the cut */
  margin-right: 5px;
}

.active-tab {
  background-color: #497e98;
  color: #fff;
}

/* Tab Line Styles */
.tab-line {
  height: 2px;
  width: 100%;
  background-color: #ddd; /* Line color */
  position: absolute;
  bottom: 0;
}

/* Tab Cut Styles */
.tab-cut {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 10px;
  background-color: #fff;
  clip-path: polygon(100% 0, 0 50%, 100% 100%); /* Cut at the right side */
}
</style>
