<template>
<div>
  <div class="registration-container">
    <h1>Registration</h1>
    <form ref="registerform" @submit.prevent="registration">
            <input type="text" v-model="username" placeholder="Username" required><br>
            <input type="password" v-model="password" placeholder="Password" required><br>
            <input type="password" v-model="confirmpassword" placeholder="Confirm Password" required><br>
            <input type="text" v-model="role" placeholder="Role" required><br>
            <button type="submit">Register</button>
    </form>
	<br>
	<br>
  </div>
</div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      password: '',
      role:'',
      confirmpassword:''
    };
  },
  methods: {
    registration() {
      // In a real app, you would perform actual authentication here.
      // Make an API request to fetch table names
      axios
          .post("https://fmsnodebackenddev-pcalvs64ua-uc.a.run.app/auth/database/registration", {
            registrationusername: this.username,
            password: this.password,
            confirmpassword:this.confirmpassword,
            role:this.role

          })
        .then((response) => {
          // Update the history array with the fetched data

           console.log(response.data.registrationsuccess)

          if(response.data.pscn)
          {
              alert('Password and confirm password doesnot match');
          }
          else if(response.data.registrationsuccess){
              alert('Registration Successfull');
               const registerform = this.$refs.registerform;

      // Reset the form using the reset method
      registerform.reset();

          }
          else{
            alert('Registration failed');
          }
        })
        .catch((error) => {
            alert('Registration failed',error);

        });

    },
  },
};

</script>
<style scoped>
.registrationpage{
  color: aliceblue;
}
.registrationheader {
  display: flex;
  justify-content: space-between;
  background-color: #006994;
  color: rgb(255, 255, 255);
  padding: 5px;
  font-family: "Georgia", Times, serif;
}

.registrationleft-header {
  flex-grow: 1;
  text-align: left;
}

.registration-container {
    max-width: 350px;
    margin: 20px auto; /* Adds spacing from top and bottom */
    padding: 55px;
    background-color: #F8F9FA;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

h1 {
    font-family: JetBrains Mono;
    color: #343A40;
    text-align: center;
    font-size: 30px;
    max-width: 500px;
    position: relative;
}

h1:before {
    content: "";
    display: block;
    width: 15%;
    height: 2px;
    background: #343A40;
    left: 0;
    top: 50%;
    position: absolute;
}

h1:after {
    content: "";
    display: block;
    width: 15%;
    height: 2px;
    background: #343A40;
    right: 0;
    top: 50%;
    position: absolute;
}
.registration-container input[type="text"],
.registration-container input[type="password"] {
width: 100%;
padding: 10px;
margin-bottom: 20px;

border: 1px solid #ccc;
outline: 2px;
font-size: 16px;
margin-top: 15px;
}

.registration-container button[type="submit"] {
width: 100%;
padding: 10px;
background-color: #4CAF50;
color: #fff;
border: none;
border-radius: 4px;
cursor: pointer;
font-size: 16px;
margin-top: 15px;
}

.registration-container button[type="submit"]:hover {
background-color: #45a049;
}

.registration-container p {
text-align: center;
margin-top: 20px;
font-size: 20px;
font-family: Inter;
margin-bottom: 10px;

}

.registration-container p a {
text-decoration: none;
color: #4CAF50;
}

.header {
background-color: #e3e1e1;
padding: 5px;
display: flex;
align-items: center;
justify-content: left; /* Centers the content horizontally */
margin-bottom: 20px; /* Adds spacing at the bottom */
color: #10086E;
margin: 2px auto;
margin-right: 30px;
margin-left: 30px;
font-family: Inter;
}

.header img {

height: 25px;
margin-right: 10px;
padding:10px;
}

.header h2 {
margin: 0;
}
.pwd{
position: relative;
}
.p-viewer {
z-index: 9999;
position: absolute;
top: 30%;
right: 10px;
}

.fa-eye {
color: #000;
}
</style>