<!-- Single File Component for Inactive asset analysis-->

<template>
  <div>
    <!-- Table Dropdown -->
    <div class="icon">
      <div class="icon">
        <div class="image-label" @click="togglegcphealthservicefuncDropdown">
          Services ↓
        </div>
        <div class="region-label">Americas</div>
      </div>
      <div
        class="gcphealthservicefunc-options"
        v-show="gcphealthservicefuncDropdownOpen"
      >
        <label v-for="(func, index) in gcphealthservicelist" :key="index">
          <input
            type="checkbox"
            v-model="gcphealthserviceselectedfunc"
            :value="func"
          />
          {{ func }}
        </label>
        <button class="small-save-button" @click="saveChanges">Save</button>
      </div>
    </div>
       <div class="card-container">
    <div class="card">
      <div class="total-card-header">
        TOTAL SERVICE
      </div>
      <div class="card-body">
        <div class="statistic">
          <span class="statistic-value">{{ this.total_service }}</span>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="av-card-header">
        AVAILABLE
      </div>
      <div class="card-body">
        <div class="statistic">
          <span class="statistic-value">{{ this.availableservice }}</span>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="un-card-header">
        UNAVAILABLE
      </div>
      <div class="card-body">
        <div class="statistic">
          <span class="statistic-value">{{ this.disruptservice }}</span>
        </div>
      </div>
    </div>
       <!-- Loading Indicator -->
    <div v-if="healthloading" class="loading-indicator">
      <div class="loading-spinner"></div>
      <div>Loading...</div>
    </div>
  </div>
    <div id="gcphealthservice-container"></div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      healthservicedata: "",
      gcphealthservicefuncDropdownOpen: false,
      healthloading:false,
      gcphealthservicelist: [
        "Access Approval",
        "Access Context Manager",
        "Access Transparency",
        "Agent Assist",
        "AI Platform Prediction",
        "AI Platform Training",
        "AlloyDB for PostgreSQL",
        "Anthos Service Mesh",
        "API Gateway",
        "Apigee",
        "Application Integration",
        "AppSheet",
        "Artifact Registry",
        "Assured Workloads",
        "AutoML Natural Language",
        "AutoML Tables",
        "AutoML Translation",
        "AutoML Video",
        "AutoML Vision",
        "Backup and DR",
        "Backup for GKE",
        "Bare Metal Solution",
        "Batch",
        "BeyondCorp Enterprise",
        "BigQuery Data Transfer Service",
        "Binary Authorization",
        "Certificate Authority Service",
        "Chronicle Security",
        "Chronicle SOAR",
        "Cloud Armor",
        "Cloud Asset Inventory",
        "Cloud Billing",
        "Cloud Build",
        "Cloud CDN",
        "Cloud Data Fusion",
        "Cloud Data Loss Prevention",
        "Cloud Debugger",
        "Cloud Domains",
        "Cloud Endpoints",
        "Cloud External Key Manager",
        "Cloud Filestore",
        "Cloud Firestore",
        "Cloud HSM",
        "Cloud IDS",
        "Cloud Key Management Service",
        "Cloud Load Balancing",
        "Cloud Logging",
        "Cloud Memorystore",
        "Cloud Monitoring",
        "Cloud NAT",
        "Cloud Natural Language API",
        "Cloud Profiler",
        "Cloud Run",
        "Cloud Security Command Center",
        "Cloud Source Repositories",
        "Cloud Spanner",
        "Cloud Storage for Firebase",
        "Cloud Talent Solution - Job Search",
        "Cloud Trace",
        "Cloud Translation",
        "Cloud Vision",
        "Cloud Workflows",
        "Cloud Workstations",
        "Contact Center AI Platform",
        "Contact Center Insights",
        "Container Registry",
        "Data Catalog",
        "Database Migration Service",
        "Dataform",
        "Dataplex",
        "Dataproc Metastore",
        "Datastream",
        "Dialogflow CX",
        "Dialogflow ES",
        "Document AI",
        "Document AI Warehouse",
        "Eventarc",
        "Firebase Authentication",
        "Firebase Test Lab",
        "Game Servers",
        "GKE fleet management",
        "Google App Engine",
        "Google BigQuery",
        "Google Cloud Bigtable",
        "Google Cloud Composer",
        "Google Cloud Console",
        "Google Cloud Dataflow",
        "Google Cloud Dataproc",
        "Google Cloud Datastore",
        "Google Cloud Deploy",
        "Google Cloud DNS",
        "Google Cloud Functions",
        "Google Cloud Identity-Aware Proxy",
        "Google Cloud IoT",
        "Google Cloud Marketplace",
        "Google Cloud NetApp Volumes",
        "Google Cloud Networking",
        "Google Cloud Pub/Sub",
        "Google Cloud Scheduler",
        "Google Cloud SQL",
        "Google Cloud Storage",
        "Google Cloud Support",
        "Google Cloud Tasks",
        "Google Compute Engine",
        "Google Distributed Cloud Edge",
        "Google Kubernetes Engine",
        "Healthcare and Life Sciences",
        "Hybrid Connectivity",
        "Identity and Access Management",
        "Identity Platform",
        "Integration Connectors",
        "Key Access Justifications",
        "Lending DocAI",
        "Looker Studio",
        "Managed Service for Microsoft Active Directory (AD)",
        "Media CDN",
        "Network Connectivity Center",
        "Network Intelligence Center",
        "Network Telemetry",
        "Payment Gateway",
        "Persistent Disk",
        "Personalized Service Health",
        "Private Service Connect",
        "Procurement DocAI",
        "Pub/Sub Lite",
        "reCAPTCHA Enterprise",
        "Recommendation AI",
        "Recommender",
        "Resource Manager API",
        "Retail API",
        "Secret Manager",
        "Service Directory",
        "Spectrum Access System",
        "Speech-to-Text",
        "Storage Transfer Service",
        "Text-to-Speech",
        "Traffic Director",
        "Transcoder API",
        "Vertex AI AutoML Image",
        "Vertex AI AutoML Tabular",
        "Vertex AI AutoML Text",
        "Vertex AI AutoML Video",
        "Vertex AI Batch Prediction",
        "Vertex AI Data Labeling	",
        "Vertex AI Explainable AI",
        "Vertex AI Feature Store",
        "Vertex AI Matching Engine",
        "Vertex AI ML Metadata",
        "Vertex AI Model Monitoring",
        "Vertex AI Model Registry",
        "Vertex AI Online Prediction",
        "Vertex AI Pipelines",
        "Vertex AI Search",
        "Vertex AI TensorBoard",
        "Vertex AI Training",
        "Vertex AI Vizier",
        "Vertex AI Workbench User Managed Notebooks",
        "Video Intelligence API",
        "Virtual Private Cloud (VPC)",
        "VMWare engine",
        "VPC Service Controls",
        "Web Risk",
      ],
      gcphealthserviceselectedfunc: [
        "Artifact Registry",
        "BigQuery Data Transfer Service",
        "Cloud Run",
        "Google App Engine",
        "Google BigQuery",
        "Google Cloud Bigtable",
        "Google Cloud SQL",
        "Google Compute Engine",
        "Google Kubernetes Engine",
        "Secret Manager",
      ],
           total_service:null,
      availableservice:null,
      disruptservice:null,
    };
  },
  created() {
    // Fetch table names when the component is created (document loaded)
    this.getgcpservicehealth();
  },
  methods: {
    saveChanges() {
      this.gcphealthservicefuncDropdownOpen = false;
      console.log("gcphealthserviceselectedfunc");
      this.getgcpservicehealth();

      console.log(this.gcphealthserviceselectedfunc);
    },
    togglegcphealthservicefuncDropdown() {
      this.gcphealthservicefuncDropdownOpen =
        !this.gcphealthservicefuncDropdownOpen;
    },
    getgcpservicehealth() {
      console.log(this.gcphealthserviceselectedfunc);

      try {
        this.healthloading = !this.healthloading

        axios
          .post("https://us-central1-argosproduction.cloudfunctions.net/gcp-servicehealth-fms/monitoring_data", {services_list: this.gcphealthserviceselectedfunc})
          .then((response) => {
            this.healthloading = !this.healthloading

            this.healthservicedata = "receive";
                this.healthservicedata = "receive";
            this.availableservice=response.data.Available
            this.total_service= this.gcphealthserviceselectedfunc.length;
            this.disruptservice = this.total_service - this.availableservice;
            document.getElementById("gcphealthservice-container").innerHTML =response.data.table_Data

          });
      } catch (error) {
        console.error("Error calling Node.js URL:", error);
      }
    },
  },
};
</script>
<style scoped>
.gcphealthservicefunc-options {
  position: absolute; /* Change this to absolute */
  border: 1px solid #ccc;
  border-top: none;
  max-height: 350px;
  overflow: auto;
  background-color: #fff;
  white-space: nowrap;
  z-index: 1;
}

.gcphealthservicefunc-options label {
  display: flex;

  padding: 5px;
  cursor: pointer;
}

.gcphealthservicefunc-options label:hover {
  background-color: #f0f0f0;
}
.small-save-button {
  display: inline-block;
  background: #4caf50; /* Button background color */
  color: #fff; /* Text color */
  padding: 5px 5px;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  transition: box-shadow 0.3s;
  float: right;
  margin-right: 2px;
  margin-bottom: 2px;
}
.image-label {
  font-size: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 2%;
  margin-top: 0.5%;
  font-weight: bold;
  display: inline-block;
  border: 2px solid #b0afaf; /* You can adjust the border width and color as needed */
  padding: 4px; /* Add padding to create space between text and border */
  margin-left: 1%;
  background: #f0f0f0;
}
.region-label {
  font-size: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: white;
  margin-bottom: 2%;
  margin-top: 0.5%;
  font-weight: bold;
  display: inline-block;
  border: 2px solid #b0afaf; /* You can adjust the border width and color as needed */
  padding: 4px; /* Add padding to create space between text and border */
  margin-right: 1%;
  background: #295f9e;
  float: right;
}
.gcphealthservice-container {
  margin-top: 1%;
  width: 100%;

  margin-left: 0.1%;
}
.gcphealthservice {
  width: 100%; /* Set a fixed width or percentage width */
  table-layout: fixed; /* Ensure a fixed layout */
}
.card-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 10px;
}

.card {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 300px;
  margin-bottom: 5px;
  margin-right: 5%;
}

.total-card-header {
  background-color: #06468b;
  color: white;
  padding: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-weight: bold;
  text-align: center;
}
.av-card-header {
  background-color: #148f16;
  color: white;
  padding: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-weight: bold;
  text-align: center;
}
.un-card-header {
  background-color: #a81313;
  color: white;
  padding: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-weight: bold;
  text-align: center;
}
.card-body {
  padding: 20px;
}

.statistic {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.statistic-label {
  margin-right: 10px;
  color: #333;
}

.statistic-value {
  font-weight: bold;
  color: #111111;
}
.loading-indicator {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>