import { read, utils } from "xlsx";
const { sheet_to_json } = utils;
import { DataFrame, toJSON } from "danfojs";
import axios from "axios";

export default {
  methods: {
    triggerFileInput() {
      document.getElementById("InactiveAssetUpload-upload-input").value = null;
      document.getElementById("InactiveAssetUpload-upload-input").click();
    },
    // Handle Excel file upload {{Header -1}}
    handleInactiveAssetFileUpload(event) {
      this.resetDataBeforeUpload()
      this.InactiveAssetFileselectedFile = event.target.files[0];
      this.InactiveAssetUploadFile();
    },
    InactiveAssetUploadFile() {
      if (!this.InactiveAssetFileselectedFile) {
        alert("Please select a file first.");
        return;
      }
      //Parse uploaded file and perform preprocessing
      const reader = new FileReader();
      reader.onload = (e) => {
        this.uploadProgress = 40;
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array", cellDates: true });
        const sheetName = workbook.SheetNames[0]; // Assuming one sheet in the Excel file
        const worksheet = workbook.Sheets[sheetName];
        var parsedData = sheet_to_json(worksheet, { header: 1 });
        const fileredsheet = parsedData.filter(
          (row) => Object.keys(row).length >= 2
        );
        const filteredData = fileredsheet.filter((row) => {
          try {
            return !Object.values(row).some(
              (cell) =>
                cell.toString().toLowerCase() === "subtotal" ||
                cell.toString().toLowerCase() === "count" ||
                cell.toString().toLowerCase() ===
                  "confidential information - do not distribute" ||
                cell.toString().toLowerCase() ===
                  "copyright © 2000-2023 salesforce.com, inc. all rights reserved."
            );
          } catch (err) {
            console.error('ERROR - Exception while filtering data---',err)

          }
        });

        function fillMissingColumns(parsedData) {
          // Assuming the header is in the first row
          const headerLength = parsedData[0].length;

          for (let i = 0; i < parsedData.length; i++) {
            const rowData = parsedData[i];
            const missingColumns = headerLength - rowData.length;

            if (missingColumns > 0) {
              // Fill in missing columns with "NA"
              for (let j = 0; j < missingColumns; j++) {
                rowData.push("");
              }
            }
          }

          return parsedData;
        }
        //Dataframe prep based on
        var df = new DataFrame(fillMissingColumns(filteredData));

        let newColumnNames = df.$data[0];

        newColumnNames = newColumnNames.map((header) =>
          header
            .replace(/\s+/g, "_")
            .replace(/:/g, "_")
            .replace(/'/g, "_")
            .replace(/\//g, "_")
            .replace(/[^\w\s]/g, "_")
            .replace(/\s+/g, "_")
        );
        console.log(newColumnNames)

        // Create a new DataFrame with the desired column names
        var columnMapping = {};

        // Iterate over the originalColumnNames and newColumnNames to build the mapping
        df.columns.forEach((originalName, index) => {
          var newName = newColumnNames[index];

          if (newName) {
            columnMapping[originalName] = newName.toLowerCase();
          }
        });
        // // Check for duplicates in the list
        // const hasDuplicates = new Set(newColumnNames).size !== newColumnNames.length;

        // if (hasDuplicates) {
        //   this.feedbackerror = 'Duplicate values found in the columns header, please rename.';
        //   this.InactiveAssetUploadProgress = 1;
        //   throw new Error('Duplicate values found in the list.');
        // }

        const hasDuplicates = new Set(newColumnNames).size !== newColumnNames.length;
        const duplicateNames = hasDuplicates ? [...newColumnNames.filter((item, index) => newColumnNames.indexOf(item) !== index)] : [];

        // If duplicates exist, display the names
        if (duplicateNames.length > 0) {
          this.feedbackerror = `Duplicate values found in the columns header, please rename (spaces will be replaced with _ while import): ${duplicateNames.join(', ')}`;
          this.InactiveAssetUploadProgress = -1;
          //throw new Error(`Duplicate values found: ${duplicateNames.join(', ')}`);
        }
        df = df.rename(columnMapping);

        let df_emptyval = df.fillNa("");

        const indicesOfEmptyValues = df_emptyval.$data[0].reduce(
          (acc, value, index) => {
            if (value === "" || value === null || value === undefined) {
              acc.push(index);
            }
            return acc;
          },
          []
        );

        const dataframewithoutEmpty = df.drop({
          columns: indicesOfEmptyValues.map((index) => df.columns[index]),
        });
        var dataframeindex = dataframewithoutEmpty.$index;

        if (dataframewithoutEmpty.columns.includes("fmsidx")) {
          dataframewithoutEmpty.drop({ columns: ["fmsidx"], inplace: true });
        }
        dataframeindex.shift();
        dataframeindex.unshift("fmsidx");
        console.log('dataframewithoutEmpty',dataframewithoutEmpty)
        let newdf = dataframewithoutEmpty.addColumn("fmsidx", dataframeindex, {
          atIndex: 0,
        });

        //Add new columns based on function checks
        var ColumnHeaderList = {
          "Big query report": [
            "FMS_Cntr_Dataset",
            "FMS_cntr_Num_Tables",
            "FMS_Cntreventtable",
            "FMS_CntrMetadatatable",
            "FMS_CntrEAVTable",
            "FMS_CntrMgatewaytable",
            "FMS_CntrTimeseriestable",
            "FMS_Cntr_createDate",
            "FMS_Cntr_SinceCreateDate",
            "FMS_Cntr_TSlastpopulatedDate",
            "FMS_Cntr_TS_sincelastpopulatedDate",
          ],
          "Edge device report": [
            "FMS_Edge_Dataset",
            "FMS_Edge_Num_Tables",
            "FMS_EdgeTimeseriestable",
            "FMS_Edgeeventtable",
            "FMS_EdgeMetadatatable",
            "FMS_EdgeMgatewaytable",
            "FMS_EdgeEAVTable",
            "FMS_Edge_createDate",
            "FMS_Edge_DaysSinceCreateDate",
            "FMS_Edge_TSlastpopulatedDate",
            "FMS_Edge_TS_sincelastpopulatedDate",
          ],
          "Controller report": [
            "FMS_Cntrrep_Dataset",
            "FMS_Cntrrep_Communication_loss",
            "FMS_Cntrrep_NoiseFault",
            "FMS_Cntrrep_Num_rows_Edge_TS_last7days",
            "FMS_Cntrrep_Num_rows_Cntr_TS_last7days",
            "FMS_Cntrrep_edgedatsettype",
          ],
          "Airvantage report": [
            "FMS_Activationstatus",
            "FMS_SIMtechnology",
            "FMS_Offer",
            "FMS_Signal_strength",
            "FMS_LASTCommunicationDate",
            "FMS_productRefName",
          ],
          "Edge devicetype report":[
            "FMS_Edge_Datasettype",
            "FMS_Edge_Datasettypecompare",
          ]

        };
        // Function to get values based on the selection function
        this.selectedfunc.forEach((selectedFunction) => {
          if (selectedFunction in ColumnHeaderList) {
            const columnValues = ColumnHeaderList[selectedFunction];
            // Iterate through columnValues and add columns to newdf
            columnValues.forEach((colValue) => {
              if (!newdf.columns.includes(colValue.toLowerCase())) {
              newdf.addColumn(colValue, Array(newdf.shape[0]).fill(""), {
                inplace: true,
              });
            }
            else{
              newdf.drop({ columns: [`${colValue.toLowerCase()}`], inplace: true });
              newdf.addColumn(colValue, Array(newdf.shape[0]).fill(""), {
                inplace: true,
              });
            }
            });
          }
        });

        let dfFilled = newdf.fillNa("");

        this.InactiveAssetuploadedData = dfFilled;

        this.tableHeaders = this.InactiveAssetuploadedData.columns;
        this.InactiveAssetUploadProgress = 60;
        // Replace whitespace with underscores in the first row
        this.tableHeaders = this.tableHeaders.map((header) =>
          header
            .replace(/\s+/g, "_")
            .replace(/:/g, "_")
            .replace(/'/g, "_")
            .replace(/\//g, "_")
            .replace(/[^\w\s]/g, "_")
            .replace(/\s+/g, "_")
        );
        this.InactiveAssetUploadProgress = 80;

        const table_name = this.InactiveAssetFileselectedFile.name;

        const tablenamedb = table_name
          .replace(".xlsx", "")
          .replace(/-/g, "_")
          .replace(/\s+/g, "_")
          .replace(/[(){}[\]<>]/g, "_");
        this.table_name_final = tablenamedb;
        const parameterMapping = {
          iot_enabled_asset_id: "selectedParameterColumns",
          serial_number: "selectedParameterColumn2",
          serial: "selectedParameterColumn2",
          serial_no: "selectedParameterColumn2",
          imei: "selectedParameterColumn3",
        };

        let parameterfoundflag = false;
        this.tableHeaders.forEach((item) => {
          if (item in parameterMapping) {
            // Dynamically set the data property based on the mapping
            parameterfoundflag = true;
            this[parameterMapping[item]] = item;
          }
        });

        if (!parameterfoundflag) {
          this.popupVisible = true;
          this.popupMessage =
            "Unable to find any of this columns in file (IoT Enabled Asset ID,IoT_Enabled_Asset_ID,Serial_Number,serial,serial_no,Serial Number), any one of these columns are mandatory to perform analysis";
          this.InactiveAssetUploadProgress = -1;
        } else {
          //POP UP PREPRATION FOR DUPLICATE ENTRIES
          let dfs = dfFilled.copy();

          //GET DUPLICATES IN IOTENABLE
          let iot_enable_final_list_dup = [];
          let serial_num_final_list_dup = [];
          let imei_final_list_dup = [];
          const iot_enable_asset_id_dup = this.selectedParameterColumns;
          if (this.selectedParameterColumns.length > 0) {
            iot_enable_final_list_dup = this.check_iot_enable_asset_duplicates(
              dfs,
              iot_enable_asset_id_dup,
              dfFilled
            );
          }
          //END OF DUPLICATES IN IOTENABLE

          //GET DUPLICATES IN SERIALNUM
          const serialnum_dup = this.selectedParameterColumn2;
          if (this.selectedParameterColumn2.length > 0) {
            serial_num_final_list_dup = this.check_serial_num_duplicates(
              dfs,
              serialnum_dup,
              dfFilled
            );
          }

          //END OF DUPLICATES IN SERIALNUM

          //GET DUPLICATES IN IMEI
          const imei_id_dup = this.selectedParameterColumn3;
          if (this.selectedParameterColumn3.length > 0) {

            imei_final_list_dup = this.check_imei_num_duplicates(
              dfs,
              imei_id_dup,
              dfFilled
            );
          }
          //END OF DUPLICATES IN IMEI

          let final_indexs = [
            ...new Set([
              ...iot_enable_final_list_dup,
              ...serial_num_final_list_dup,
              ...imei_final_list_dup,
            ]),
          ];
          this.final_indicies = final_indexs;

          if (final_indexs.length > 0) {
            let duplicated_data_rows = final_indexs.map(
              (index) => dfFilled.values[index]
            );
            this.duplicated_data_rows_user = new DataFrame(
              duplicated_data_rows,
              { columns: dfFilled.columns }
            );

            this.duplicateRows = this.duplicated_data_rows_user.values;

            //Show the modal
            this.showModal = true;
          } else {
            this.create_table_insertdata();
          }
        }
      };
      // Reset the progress bar (optional)
      this.InactiveAssetUploadProgress = 20;
      reader.readAsArrayBuffer(this.InactiveAssetFileselectedFile);
    },
    create_table_insertdata() {
       //Check duplicates before analysis and generate erro
      let anaiot_enable_final_list_dup = [];
      let anaserial_num_final_list_dup = [];
      let anaimei_final_list_dup = [];
      const anaiot_enable_asset_id_dup = this.selectedParameterColumns;
      let finaldatacopy = this.InactiveAssetuploadedData.copy();
      if (this.selectedParameterColumns.length > 0) {
        anaiot_enable_final_list_dup = this.check_iot_enable_asset_duplicates(
          finaldatacopy,
          anaiot_enable_asset_id_dup,
          this.InactiveAssetuploadedData
        );
      }
      //END OF DUPLICATES IN IOTENABLE

      //GET DUPLICATES IN SERIALNUM
      const anaserialnum_dup = this.selectedParameterColumn2;
      if (this.selectedParameterColumn2.length > 0) {
        anaserial_num_final_list_dup = this.check_serial_num_duplicates(
          finaldatacopy,
          anaserialnum_dup,
          this.InactiveAssetuploadedData
        );
      }

      //END OF DUPLICATES IN SERIALNUM

      //GET DUPLICATES IN IMEI
      const anaimei_id_dup = this.selectedParameterColumn3;
      if (this.selectedParameterColumn3.length > 0) {
        anaimei_final_list_dup = this.check_imei_num_duplicates(
          finaldatacopy,
          anaimei_id_dup,
          this.InactiveAssetuploadedData
        );
      }
      //END OF DUPLICATES IN IMEI

      let anafinal_indexs = [
        ...new Set([
          ...anaiot_enable_final_list_dup,
          ...anaserial_num_final_list_dup,
          ...anaimei_final_list_dup,
        ]),
      ];

      if (anafinal_indexs.length > 0) {
        this.InactiveAssetUploadProgress = -1;
        this.feedbackerror =
          "Duplicate value still exists in data, Remove duplicates";
      } else {
        //prepare missing value for iot enable asset id
        let iotenablecolumnname = this.selectedParameterColumns;
        let iotenablecolumnIndex = -1;
        let serialnumcolumnname = this.selectedParameterColumn2;
        let serialnumcolumnIndex = -1;
        let imeinumcolumnname = this.selectedParameterColumn3;
        let imeinumcolumnIndex = -1;

        if (iotenablecolumnname.length > 0) {
          // Find the index of the specified column
          for (
            let i = 0;
            i < this.InactiveAssetuploadedData.columns.length;
            i++
          ) {
            if (
              this.InactiveAssetuploadedData.columns[i] === iotenablecolumnname
            ) {
              iotenablecolumnIndex = i;
              break;
            }
          }

          // If the column index is found, iterate over the rows and check if the specified column is empty
          if (iotenablecolumnIndex !== -1) {
            this.InactiveAssetuploadedData.values.forEach((row) => {
              if (row[iotenablecolumnIndex] === "") {
                this.iotenablemissing_row_index.push(row[0]);
              }
            });
          }

        }

        //Finish empty checking

        //check seriam num empty
        if (serialnumcolumnname.length > 0) {
          // Find the index of the specified column
          for (
            let i = 0;
            i < this.InactiveAssetuploadedData.columns.length;
            i++
          ) {
            if (
              this.InactiveAssetuploadedData.columns[i] === serialnumcolumnname
            ) {
              serialnumcolumnIndex = i;
              break;
            }
          }

          // If the column index is found, iterate over the rows and check if the specified column is empty
          if (serialnumcolumnIndex !== -1) {
            this.InactiveAssetuploadedData.values.forEach((row) => {
              if (row[serialnumcolumnIndex] === "") {
                this.serialnummissing_row_index.push(row[0]);
              }
            });
          }
        }
        //Finish empty checking

        //check imei num empty
        if (imeinumcolumnname.length > 0) {
          // Find the index of the specified column
          for (
            let i = 0;
            i < this.InactiveAssetuploadedData.columns.length;
            i++
          ) {
            if (
              this.InactiveAssetuploadedData.columns[i] === imeinumcolumnname
            ) {
              imeinumcolumnIndex = i;
              break;
            }
          }

          // If the column index is found, iterate over the rows and check if the specified column is empty
          if (imeinumcolumnIndex !== -1) {
            this.InactiveAssetuploadedData.values.forEach((row) => {
              if (row[imeinumcolumnIndex] === "") {
                this.imeimissing_row_index.push(row[0]);
              }
            });
          }

        }

        this.loadingtimeincrease = !this.loadingtimeincrease;
        axios
          .post("https://fmsnodebackenddev-pcalvs64ua-uc.a.run.app/auth/createtable_insertdata", {
            data: toJSON(this.InactiveAssetuploadedData),
            tablename: this.table_name_final,
            loggeduser: this.$route.params.username, // Include the value as a parameter
            selectedfunction: this.selectedfunc,
            tableheaders: this.tableHeaders,
          })
          .then((response) => {
            // Handle the server response here
            console.log(response.data.message)
            if (response.data.message === "Success") {
              this.feedbackerror = null;
              this.loadingtimeincrease = !this.loadingtimeincrease;

              this.InactiveAssetUploadProgress = 100;
              this.childProp = this.InactiveAssetuploadedData;
            } else {
              this.InactiveAssetUploadProgress = -1;
            }
          })
          .catch((error) => {
            (

              this.feedbackerror =
              "ERROR - Unable to parse with predefined function like Check,sum etc..., Please change and upload again or rename file if uploaded before"),
              error;
            // Handle errors here
            this.InactiveAssetUploadProgress = -1;
            this.loadingtimeincrease = !this.loadingtimeincrease;

            console.error("ERROR - Error while table creation and data creation in exc ----",error)
            if (error.response) {
              // The request was made and the server responded with a status code
              console.error("Error status:", error.response.status);
              console.error("Error message:", error.response.data.message);
              this.feedbackerror = error.response.data.message
            } else if (error.request) {
              // The request was made but no response was received
              this.feedbackerror = "No response received"
            } else {
              // Something happened in setting up the request that triggered an Error
              this.feedbackerror = "Error setting up the request:", error.message
            }


          });
      }
    },
  },
};
