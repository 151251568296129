<template>
  <div>
    <table>
      <!-- Table headers -->
      <thead>
        <tr>
          <th v-for="(column, index) in columns" :key="index" @click="sortBy(column.key)">
            {{ column.label }}
            <span v-show="sortOrder === 'asc' && sortColumn === column.key">&#9650;</span>
            <span v-show="sortOrder === 'desc' && sortColumn === column.key">&#9660;</span>
          </th>
        </tr>
      </thead>
      <!-- Table body -->
      <tbody>
        <tr v-for="(item, index) in sortedData" :key="index">
          <td v-for="(column, colIndex) in columns" :key="colIndex">{{ item[column.key] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [
        // Your data here
      ],
      sortOrder: 'asc',
      sortColumn: null,
      columns: [
        { label: 'Column 1', key: 'column1' },
        { label: 'Column 2', key: 'column2' },
        // Add more columns as needed
      ],
    };
  },

  methods: {
    sortBy(column) {
      if (column === this.sortColumn) {

        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortOrder = 'asc';
        this.sortColumn = column;
      }
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
