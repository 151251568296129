<!-- Single file component for Inactive asset details  page -->
<!-- template : Select file upload,upload file, parse it,display data in table,analyse,export -->

<template>
  <div>
    <div class="InactiveAssetUpload-main">
      <!-- Left side file upload  -->
      <div class="InactiveAssetUpload-upload">
        <div class="InactiveAssetUpload-box">
          <div class="icon">
            <div class="icon" @click="toggleInactiveAssetFunctionDropdown">
              <img
                src="..\..\AnalysisPage\images\functions.png"
                width="40"
                height="25"
              />
              <div class="image-label">Functions</div>
            </div>
            <div
              class="InactiveAssetFunction-options"
              v-show="InactiveAssetFunctionDropdownOpen"
            >
              <label v-for="(func, index) in functionlist" :key="index">
                <input type="checkbox" v-model="selectedfunc" :value="func" />
                {{ func }}
              </label>
              <button class="small-save-button" @click="saveChanges">
                Save
              </button>
            </div>
          </div>
          <div class="icon" @click="triggerFileInput">
            <img
              src="../../AnalysisPage/images/upload.png"
              width="40"
              height="25"
              alt="Upload File"
            />
            <div class="image-label">Upload</div>
          </div>
          <!-- Actual file input, hidden from view -->
          <input
            type="file"
            id="InactiveAssetUpload-upload-input"
            style="display: none"
            @change="handleInactiveAssetFileUpload"
            accept=".xlsx, .xls"
          />

          <div class="icon" @click="startInactiveAssetAnalysis">
            <img
              src="../../AnalysisPage/images/analyse.png"
              width="40"
              height="25"
            />
            <div class="image-label">Analyse</div>
          </div>

          <div class="icon" @click="InactiveAssetAnalysisexportToExcel">
            <img
              src="../../AnalysisPage/images/export.png"
              width="40"
              height="25"
            />
            <div v-if="InactiveAssetAnalysisexportloading" class="image-label">
              Loading...
            </div>
            <div v-else class="image-label">Export</div>
          </div>
        </div>
      </div>
      <div class="icon-container">
        <div class="reset-icon" @click="resetData">
          <span class="help-icon-unicode">⭮</span>
          <div class="image-label">Reset</div>
        </div>

        <div class="help-icon" @click="showHelp">
          <span class="help-icon-unicode">&#9432;</span>
          <!-- Unicode character for information source ℹ -->
          <div class="image-label">Help</div>
        </div>
      </div>
      <!-- Show the help pop-up modal when needed -->
      <div v-if="isHelpVisible" class="help-modal">
        <div class="help-content">
          <h2>INACTIVE ASSET ANALYSIS</h2>
          <h4>PROCESS</h4>
          <p>Step 1: Select function for analysis using the "Function" icon.</p>
          <p>Step 2: Upload EXCEL file using the "Upload" icon.</p>
          <p>Step 3: Click the "Analyse" button to start the analysis.</p>
          <p>
            Step 4: Once the analysis is complete,export the results
            using the "Export" button.
          </p>
          <p>
            Step 5: Reset page after analysis completed or after analysis report exported
            before uploading new file again.
          </p>
          <h4>NOTE : </h4>
          <p>
            Data in any one of these IOT Enabled Asset ID, Serial Number, IMEI columns are needed for analysis IOT Enabled Asset ID or Serial Number or IMEI.
          </p>
                    <p>
            For airvantage report IMEI column is mandatory.
          </p>
          <p>
            Excel upload and analysis progress will be shown in progress bar.
          </p>
          <button @click="closeHelp">Close</button>
        </div>
      </div>
    </div>
    <div class="underline-grey"></div>
                    <div v-if="loadingtimeincrease">
              Loading...
            </div>
    <div
      class="InactiveAssetUpload-progress-bar"
      v-if="InactiveAssetUploadProgress !== null"
      :class="{ error: InactiveAssetUploadProgress === -1 }"
    >
      <div
        class="InactiveAssetUpload-progress-fill"
        :style="{ width: InactiveAssetUploadProgress + '%' }"
      ></div>
      <div class="InactiveAssetUpload-text">
        {{
          InactiveAssetUploadProgress === -1
            ? "err"
            : InactiveAssetUploadProgress + "%"
        }}
      </div>
    </div>
    <div class="InactiveAssetUpload-error" v-if="feedbackerror">
      {{ this.feedbackerror }}
    </div>
    <div v-if="popupVisible" class="popup">
      <div class="popup-content">
        <p>{{ popupMessage }}</p>
        <button @click="closePopup">Close</button>
      </div>
    </div>
    <!-- Table Dropdown -->
    <div class="InactiveAssetBody-data-table-container">
      <table
        v-if="childProp"
        class="InactiveAssetBody-data-table"
        id="InactiveAssetBody-data-table"
      >
        <thead>
          <tr>
            <th v-for="(value, key) in this.tableHeaders" :key="key">
              {{ value }}
            </th>
          </tr>
        </thead>

        <tbody>
          <!-- Display each row -->
          <tr
            v-for="(row, index) in this.childProp.values.slice(1)"
            :key="index"
          >
            <!-- Display each cell in the row -->
            <td
              v-for="(value, column) in row"
              :key="column"
              :id="`${this.tableHeaders[column]}-` + row[0]"
            >
              {{ value }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="showModal" class="modal-overlay">
      <div class="modal">

         <div class="header-container">
      <div class="header-left">
        <h3>Duplicate rows : Select data to remove, then click on SAVE (Duplicate data will impact on analysis report)</h3>
      </div>
      <div class="header-right">
                <div v-if="duplicatevalueexportloading">
              Loading...
            </div>
        <button  v-else @click="duplicate_data_modal_exportToExcel">Export</button>
      </div>
    </div>

        <table
        class="duplicatevalu-data-table"
        id="duplicatevalu-data-table"
        >
          <thead>
            <tr>
              <th>Select</th>
              <!-- Moved "Select" column to the beginning -->
              <th v-for="(value, key) in this.tableHeaders" :key="key"  @click="sortBy(value)">
                {{ value }}
                <span v-show="sortOrder === 'asc' && sortColumn === value">&#9650;</span>
            <span v-show="sortOrder === 'desc' && sortColumn === value">&#9660;</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in duplicateRows" :key="index">
              <td>
                <input type="checkbox" v-model="selectedRows" :value="index" />
              </td>
              <td v-for="(value, key) in row" :key="key">{{ value }}</td>
            </tr>
          </tbody>
        </table>
        <button @click="saveSelectedRows">Save</button>
        <button @click="closeModal">Close</button>

      </div>
    </div>
  </div>
</template>
<script>

import SaveFunctionAnalaysisParameter from './assetanalysisScript/SaveFunctionAnalysis.js';
import ExcelFileUpload from './assetanalysisScript/ExcelFileUpload.js';
import startAnalysis from './assetanalysisScript/AnalysisStart.js';
import exportExcel from './assetanalysisScript/ExportExcel.js';
import duplicatesExcel from './assetanalysisScript/DuplicatesChecks.js';
export default {
  mixins: [SaveFunctionAnalaysisParameter,ExcelFileUpload,startAnalysis,exportExcel,duplicatesExcel],
  data() {
    return {
      InactiveAssetFileselectedFile: null,
      InactiveAssetUploadProgress: null,
      table_name_final: null,
      tableHeaders: [],
      InactiveAssetuploadedData: null,
      selectedParameterColumns: [],
      selectedParameterColumn2: [],
      selectedParameterColumn3: [],
      loadingtimeincrease:false,
      InactiveAssetAnalysisProgress: null,
      InactiveAssetAnalysisexportloading: false,
      duplicatevalueexportloading:false,
      childProp: null,
      popupVisible: false,
      popupMessage: null,
      isHelpVisible: false,
      feedbackerror: null,
      InactiveAssetFunctionDropdownOpen: false,
      functionlist: [
        "Big query report",
        "Edge device report",
        "Controller report",
        "Airvantage report",
        "Edge devicetype report"
      ],
      selectedfunc: ["Big query report"],
      bqinterval: null,
      edinterval: null,
      continterval: null,
      airvantinterval: null,
      edgedevicedatasettypeinterval:null,
      iotenablemissing_row_index: [],
      serialnummissing_row_index:[],
      imeimissing_row_index: [],
      completed_Task: 0,
      showModal: false,
      duplicateRows: [],
      selectedRows: [],
      final_indicies:[],
      sortOrder: 'asc',
      sortColumn: null,
      duplicated_data_rows_user:[]
    };
  },
  methods:{
      resetData() {
      (this.InactiveAssetFileselectedFile = null),
        (this.InactiveAssetUploadProgress = null),
        (this.table_name_final = null),
        (this.tableHeaders = []),
        (this.InactiveAssetuploadedData = null),
        (this.selectedParameterColumns = []),
        (this.selectedParameterColumn2 = []),
        (this.selectedParameterColumn3 = []),
        (this.loadingtimeincrease = false),
        (this.InactiveAssetAnalysisexportloading = false),
        (this.duplicatevalueexportloading = false),
        (this.childProp = null),
        (this.popupVisible = false),
        (this.popupMessage = null),
        (this.isHelpVisible = false),
        (this.feedbackerror = null),
        (this.InactiveAssetFunctionDropdownOpen = false),
        (this.functionlist = [
          "Big query report",
          "Edge device report",
          "Controller report",
          "Airvantage report",
          "Edge devicetype report"

        ]),
        (this.selectedfunc = ["Big query report"]),
        (this.iotenablemissing_row_index = []),
        (this.serialnummissing_row_index = []),
        (this.imeimissing_row_index = []),
        (this.completed_Task = 0),
        (this.showModal = false),
        (this.duplicateRows = []),
        (this.selectedRows = []),
        (this.final_indicies = []),
        this.saveChanges();
      clearInterval(this.bqinterval);
      clearInterval(this.edinterval);
      clearInterval(this.continterval);
      clearInterval(this.airvantinterval);
      clearInterval(this.edgedevicedatasettypeinterval);
    },
    resetDataBeforeUpload() {
      (this.InactiveAssetFileselectedFile = null),
        (this.InactiveAssetUploadProgress = null),
        (this.table_name_final = null),
        (this.tableHeaders = []),
        (this.InactiveAssetuploadedData = null),
        (this.selectedParameterColumns = []),
        (this.selectedParameterColumn2 = []),
        (this.selectedParameterColumn3 = []),
        (this.loadingtimeincrease = false),
        (this.InactiveAssetAnalysisexportloading = false),
        (this.duplicatevalueexportloading = false),
        (this.childProp = null),
        (this.popupVisible = false),
        (this.popupMessage = null),
        (this.isHelpVisible = false),
        (this.feedbackerror = null),
        (this.InactiveAssetFunctionDropdownOpen = false),
        (this.iotenablemissing_row_index = []),
        (this.serialnummissing_row_index = []),
        (this.imeimissing_row_index = []),
        (this.completed_Task = 0),
        (this.showModal = false),
        (this.duplicateRows = []),
        (this.selectedRows = []),
        (this.final_indicies = []),
        this.saveChanges();
        clearInterval(this.bqinterval);
        clearInterval(this.edinterval);
        clearInterval(this.continterval);
        clearInterval(this.airvantinterval);
        clearInterval(this.edgedevicedatasettypeinterval);
    },
    showHelp() {
      this.isHelpVisible = true;
    },

    closeHelp() {
      this.isHelpVisible = false;
    },

  }

};
</script>
<style scoped>
.InactiveAssetBody-search-container {
  text-align: right;
  display: flex;
  align-items: center;
}
#InactiveAssetBody-specific-input {
  border: 1px solid #ccc;
  padding: 5px 10px; /* Adjust the padding as needed */
  border-radius: 5px;
}
.icon {
  margin-right: 5%;
  margin-left: 5%;
  text-align: center;
  cursor: pointer;
}
.help-icon {
  text-align: right;
  display: flex-end;
  align-items: center;
  margin-right: 1%;
  font-weight: bold;
  cursor: pointer;
}
.icon-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1%;
}

.reset-icon {
  display: inline-block;
  margin-right: 10px; /* Adjust margin as needed */
  font-weight: bold;
  cursor: pointer;
}

/* Create a class for the grey underline */
.underline-grey {
  border-bottom: 1px solid #ccc; /* Add a grey border at the bottom */
  width: 100%; /* Ensure the underline extends to full width */
}
.image-label {
  font-size: 10px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
}
.InactiveAssetUpload-main {
  display: flex;
  display: flex;
  justify-content: space-between; /* Align the children at both ends */
  align-items: center;
}
.InactiveAssetUpload-upload {
  flex-direction: column;
  align-items: center;
  margin-left: 1%;
  margin-top: 1%;
  max-height: 75px;
}
.InactiveAssetUpload-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.InactiveAssetUpload-selectfile {
  flex-grow: 1;
  text-align: left;
  margin-right: 10px;
  border: 1px solid #0d45a5;
  border-radius: 25px;
}
.InactiveAssetUpload-upload-input {
  background-color: #2d353a;
  color: white;
  padding: 5px 15px;
  border: none;
}
input[type="file"]::file-selector-button {
  margin-right: 20px;
  border: black;
  background: #0d45a5;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

input[type="file"]::file-selector-button:hover {
  background: #3c73d2;
}
.InactiveAssetUpload-button {
  flex-grow: 1;
  text-align: right;
}

.InactiveAssetUpload-button button {
  background-color: #3498db; /* Blue color, you can change this to your preferred color */
  color: white;
  padding: 5px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px; /* Add some rounded corners */
  transition: transform 0.2s ease-in-out; /* Add a subtle animation */
  position: relative; /* Ensure the arrow is positioned correctly */
}

.InactiveAssetUpload-button button:hover {
  transform: scale(1.05); /* Enlarge the button slightly on hover */
}
.InactiveAssetUpload-progress-bar {
  width: 100%;
  background-color: #f1f1f1;
  height: 10px;
  border-radius: 3px;
  margin-top: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.InactiveAssetUpload-progress-fill {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.3s ease-in-out;
}

.InactiveAssetUpload-text {
  padding: 5px;
  color: #3498db;
  font-weight: bold;
  font-size: 8px;
}

/* Add the following style to change the progress bar color to red */
.InactiveAssetUpload-progress-bar.error .InactiveAssetUpload-progress-fill {
  background-color: red;
}

/* Add this style to change the text color to red */
.InactiveAssetUpload-progress-bar.error .InactiveAssetUpload-progress-text {
  color: red;
}

.InactiveAssetAnalysis-container {
  display: inline-block;
  flex-direction: column;
  align-items: center;
  padding: 9px;
  margin-left: 1%;
  background: #d3f0f0;
  margin-top: 1%;
  float: left; /* Float it to the left side */
  max-height: 75px;
}
.InactiveAssetAnalysis-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.InactiveAssetAnalysis-select {
  flex-grow: 1;
  text-align: left;
  margin-right: 10px;
  border: 1px solid #0d45a5;
}
.InactiveAssetAnalysis-select-header {
  background-color: #0d45a5;
  padding: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: auto;
  color: white;
}
.InactiveAssetAnalysisarrow {
  font-size: 14px; /* Adjust the size as needed */
  margin-left: 5px; /* Add spacing between text and arrow */
}

.InactiveAssetParameter-options {
  position: absolute; /* Change this to absolute */
  border: 1px solid #ccc;
  border-top: none;
  max-height: 350px;
  overflow: auto;
  background-color: #fff;
  white-space: nowrap;
  z-index: 1;
}

.InactiveAssetParameter-options label {
  display: flex;

  padding: 5px;
  cursor: pointer;
}

.InactiveAssetParameter-options label:hover {
  background-color: #f0f0f0;
}

.InactiveAssetFunction-options {
  position: absolute; /* Change this to absolute */
  border: 1px solid #ccc;
  border-top: none;
  max-height: 350px;
  overflow: auto;
  background-color: #fff;
  white-space: nowrap;
  z-index: 1;
}

.InactiveAssetFunction-options label {
  display: flex;

  padding: 5px;
  cursor: pointer;
}

.InactiveAssetFunction-options label:hover {
  background-color: #f0f0f0;
}

.InactiveAssetAnalysis-button button {
  background-color: #3498db; /* Blue color, you can change this to your preferred color */
  color: white;
  padding: 5px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px; /* Add some rounded corners */
  transition: transform 0.2s ease-in-out; /* Add a subtle animation */
  position: relative; /* Ensure the arrow is positioned correctly */
}

.InactiveAssetAnalysis-button:hover {
  transform: scale(1.05); /* Enlarge the button slightly on hover */
}
.InactiveAssetAnalysis-export-button button {
  background-color: #3498db; /* Blue color, you can change this to your preferred color */
  color: white;
  padding: 5px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px; /* Add some rounded corners */
  transition: transform 0.2s ease-in-out; /* Add a subtle animation */
  position: relative; /* Ensure the arrow is positioned correctly */
  margin-left: 50%;
  margin-top: 10%;
  display: flex-end;
  float: right;
}

.InactiveAssetAnalysis-export-button:hover {
  transform: scale(1.05); /* Enlarge the button slightly on hover */
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  z-index: 999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  text-align: center;
}

.popup-content {
  text-align: center;
}

.popup button {
  background-color: #3498db;
  color: white;
  padding: 5px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: transform 0.2s ease-in-out;
  margin-top: 10px;
}

.popup button:hover {
  transform: scale(1.05);
}
.help-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.help-content {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 20px;
  text-align: left;
  max-width: 70%;
  font-size: 15px;
}

.help-content button {
  background-color: #3498db; /* Blue color, you can change this to your preferred color */
  color: white;
  padding: 5px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
}

.help-content button:hover {
  transform: scale(1.05); /* Enlarge the button slightly on hover */
}
.small-save-button {
  display: inline-block;
  background: #4caf50; /* Button background color */
  color: #fff; /* Text color */
  padding: 5px 5px;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  transition: box-shadow 0.3s;
  float: right;
  margin-right: 2px;
  margin-bottom: 2px;
}
.InactiveAssetBody-data-table-container {
  margin-top: 1%;
  width: 100%;

  margin-left: 0.1%;
}

.InactiveAssetBody-data-table {
  border-collapse: collapse;
  /* You can set a specific width or use a percentage value based on your design */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-collapse: collapse;
  width: 100%; /* Set a fixed width or percentage width */
  table-layout: fixed; /* Ensure a fixed layout */
}

.InactiveAssetBody-data-table th,
.InactiveAssetBody-data-table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 4px;
  font-size: 10px;
  overflow-wrap: break-word; /* Allow long words to break */
}

.InactiveAssetBody-data-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  position: sticky;
  top: 0;
}

.InactiveAssetBody-data-table thead th {
  position: sticky;
}

.InactiveAssetBody-data-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.InactiveAssetBody-data-table tr:hover {
  background-color: #ddd;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 90%;
  max-height: 70%;
  overflow: auto;
  position: relative; /* Position relative for absolute positioning */
}

.modal-content {
  max-height: calc(100% - 40px); /* Adjust based on the modal padding */
  overflow: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
    font-size: 10px;

}

.modal-buttons {
  margin-top: 10px;
  text-align: right;
}

button {
margin-top: 1%;
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  color: #fff;
  background-color: #4CAF50; /* Green background color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  transition: transform 0.3s; /* Add a smooth transition effect */
  margin-left: 2%;
  margin-right: 2%;
}

button:hover {
  transform: translateY(-2px); /* Move the button up on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase the shadow on hover */
}
.modal h3 {
  position: relative;
  top: 10px;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
}
.header-container {
  display: flex;
  justify-content: space-between;
}
.InactiveAssetUpload-error{
  font-weight: bold;
  color: brown;
}

</style>