<template>
  <div>
    <div class="header" v-if="loggedIn">
      <div class="left-header">
        <b>Fleet Management</b>
        <button @click="toggleSidebar" class="toggle-button">
          <span class="hamburger-icon">{{ isCollapsed ? "☰" : "✖" }}</span>
        </button>
      </div>
      <div class="right-header">
        <div class="header-dropdown">
          <div class="header-content">
            <div class="profile-dropdown">
              <img
                src="/contact.png"
                width="35"
                height="26"
                class="profile-image"
                @click="toggleHeaderDropdown"
              />
              <ul class="dropdown-menu" v-if="isHeaderDropdownOpen">
                <li class="user-details">
                  <span class="user-name">{{ username }}</span
                  ><br />
                  <span class="user-role">{{ role }}</span
                  ><br />
                </li>
                <li class="user-option">
                  <a @click="logout" style="cursor: pointer">Logout</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="app-container" v-if="loggedIn">
      <nav class="sidebar" :style="sidebarStyles">
        <!-- <ul>
          <router-link to="/homePage" class="router-link-active">
            <span class="home-icon">{{
              isCollapsed
                ? "&nbsp;&#127968;&nbsp;"
                : "&nbsp;&#127968;&nbsp;Dashboard"
            }}</span>
          </router-link>
        </ul> -->
                <ul >
         <router-link
                  :to="'/assetanalysislanding/'+ username"
                  class="router-link-active"
                >
            <div class="icon-container">
              <img src="/analyseicon.png" class="icon-image" />
              <span v-if="!isCollapsed" class="home-icon"><h4>Analysis</h4></span>
            </div>
          </router-link>

        </ul>

        <!-- <ul >
          <router-link to="/simcardmanagement" class="router-link-active">
            <div class="icon-container">
              <img src="/simcard.png" class="icon-image" />
              <span v-if="!isCollapsed" class="home-icon"><h4>SIM cards</h4></span>
            </div>
          </router-link>

        </ul> -->
        <ul v-if="adminflag">
          <router-link to="/RegistrationPage" class="router-link-active">
            <div class="icon-container">
              <img src="/registericon.png" class="icon-image" />
              <span v-if="!isCollapsed" class="home-icon"><h4>Register</h4></span>
            </div>
          </router-link>
        </ul>
        <ul v-if="adminflag">
          <router-link to="/gcphealthservice" class="router-link-active">
            <div class="icon-container">
              <img src="/googleservice.png" class="icon-image" />
              <span v-if="!isCollapsed" class="home-icon"><h4>Products</h4></span>
            </div>
          </router-link>
        </ul>
      </nav>

      <div class="main-content">
        <router-view></router-view>
      </div>
    </div>
    <Login @login-success="handleLoginSuccess" v-else />
    <!-- Show Login component when not loggedIn -->
  </div>
</template>


<script>
import Login from "../src/components/UserValidation/LoginPage.vue";
export default {
  data() {
    return {
      isCollapsed: false,
      isChildItemsVisible: false,
      loggedIn: false,
      isHeaderDropdownOpen: false, // Add a data property for dropdown visibility
      username: "",
      role: "",
      adminflag: false,
    };
  },
  computed: {
    sidebarStyles() {
      // Define the minimum width when collapsed (adjust the width as needed)
      const minCollapsedWidth = "30px"; // Set the width of the icon
      return {
        minWidth: this.isCollapsed ? minCollapsedWidth : "12%", // Set the collapsed width or 12%
      };
    },
  },
  methods: {
    logout() {
      localStorage.removeItem("loggedIn");
      localStorage.removeItem("username");
      localStorage.removeItem("role");

      this.loggedIn = false;
      this.username = "";
      this.role = "";
      this.adminflag = false;
    },
    toggleSidebar() {
      this.isCollapsed = !this.isCollapsed;
    },
    toggleChildItems() {
      this.isChildItemsVisible = !this.isChildItemsVisible;
    },
    handleLoginSuccess(data) {
      this.loggedIn = true; // Set loggedIn to true on successful login
      this.loggedIn = true; // Set loggedIn to true on successful login
      this.isHeaderDropdownOpen = !this.isHeaderDropdownOpen;
      const { username, role } = data;
      console.log("resultusername");
      console.log(username);
      console.log(role);
      this.username = username;
      this.role = role;
      if (username === "admin") {
        this.adminflag = true;
      }
      localStorage.setItem("loggedIn", "true");
      localStorage.setItem("username", username);
      localStorage.setItem("role", role);
    },
    toggleHeaderDropdown() {
      // Add a method to toggle the header dropdown
      this.isHeaderDropdownOpen = !this.isHeaderDropdownOpen;
    },
  },
  components: {
    Login,
  },
  created() {
    const loggedIn = localStorage.getItem("loggedIn");
    const username = localStorage.getItem("username");
    const role = localStorage.getItem("role");

    if (loggedIn === "true" && username && role) {
      this.loggedIn = true;
      this.username = username;
      this.role = role;
      if (username === "admin") {
        this.adminflag = true;
      }
    }
  },
};
</script>

<style scoped>
/* Sidebar container */
.app-container {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
  min-width: 100px;
}
.main-content {
  min-width: 200px; /* Set your minimum width */
  flex-grow: 1; /* Allow it to grow and take available space */

  /* You can also set a maximum width if needed */
  max-width: 100%; /* Set your maximum width */
}
.sidebar-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  background-color: #006994;
  color: rgb(255, 255, 255);
  padding: 5px;
  font-family: "Georgia", Times, serif;
   position: sticky;
   top:0;
   z-index: 1000; /* Adjust z-index as needed */
}

.left-header {
  flex-grow: 1;
  text-align: left;
}

.right-header {
  flex-grow: 1;
  text-align: right;
}

/* Sidebar */
.sidebar {
  background-color: #ebe6e6;
  color: rgb(56, 56, 56);
  transition: width 0.3s;
  display: flex;
  flex-direction: column;
  display: inline-block;

}

.sidebar-links {
  margin-top: 20px;
}


/* Toggle button (hamburger icon) */
.toggle-button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  transition: left 0.3s;
  margin-left: 1%;
}

/* Sidebar collapsed state */
.collapsed .sidebar-toggle {
  width: 30px; /* Adjust the width when collapsed */
}

/* Child items */
.toggle-arrow {
  font-size: 12px;
  vertical-align: middle;
  margin-left: 5px;
  cursor: pointer;
}

.expanded {
  transform: rotate(90deg);
}

.child-items {
  margin-left: 20px;
  display: block;
}

.expanded + .child-items {
  display: block;
}
.nested-item {
  margin-left: 8%; /* Adjust the margin to control the nesting level */
  display: block;
  font-weight: bold;
  font-family: "Franklin Gothic Medium", "Arial", Arial, sans-serif;
}
a.router-link-active {
  text-decoration: none;
  color: rgb(56, 56, 56);
}
ul {
  margin-top: 3%;
}
li {
  margin-top: 1%;
  text-align: start;
}

.profile-dropdown {
  position: relative;
  display: inline-block;
}

.profile-image {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  left: auto;
  z-index: 1;
  display: block;
  min-width: 200px;
  padding: 10px;
  background-color: #f1f1f1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.user-details {
  margin-bottom: 10px;
  text-align: center;
}

.user-name {
  font-weight: bold;
  color: black;
}

.user-role {
  color: #777;
}

.dropdown-menu a {
  display: block;
  padding: 5px;
  text-decoration: none;
  color: #333;
}

.dropdown-menu a:hover {
  background-color: #f0f0f0;
}
.icon-container {
  display: flex;
  align-items: center;
  padding: 1%;
  color: rgb(10, 10, 10);
    background: #ddd;
    padding: 5px 6px;
    text-decoration: none;
}

.icon-image {
  margin-right: 5px;
  width: 25px; /* Adjust as needed to provide spacing between image and text */
}

.history-icon-image {
  height: 15%;
  width: 15%;

  margin-left: 8%; /* Adjust the margin to control the nesting level */
  display: block;
  font-weight: bold;
  font-family: "Franklin Gothic Medium", "Arial", Arial, sans-serif;
}
</style>
