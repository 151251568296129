import axios from "axios";
import {utils } from "xlsx";

export default {

    methods: {
      async InactiveAssetAnalysisexportToExcel() {
      this.InactiveAssetAnalysisexportloading = true;
      try {
        const table = document.getElementById("InactiveAssetBody-data-table");
        var opts = {
          raw: true,
        };
        const table_Sheet_Data = utils.table_to_sheet(table, opts);
        // Convert the worksheet data to JSON format
        const jsonData = utils.sheet_to_json(table_Sheet_Data, { header: 1 });
        // Define the headers and data to be sent to the backend
        const headers = jsonData[0]; // Assuming the first row contains headers
        headers.splice(0, 1); // Remove the first columns from headers

        const data = jsonData.slice(1); // Remove the first row (headers)

        // Define the request payload
        const payload = {
          headers: headers,
          data: data,
        };

        // Send a POST request to the backend to generate and download the Excel file
        try {
          axios
            .post("https://fmsnodebackenddev-pcalvs64ua-uc.a.run.app/auth/exportfunction/", payload, {
              responseType: "blob", // Receive response as a blob (binary data)
            })
            .then((response) => {
              // Create a blob URL for the response data
              const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });
              const url = window.URL.createObjectURL(blob);

              // Create a link element to trigger the download
              const a = document.createElement("a");
              a.href = url;
              a.download = `${this.table_name_final}.xlsx`;

              // Trigger a click event to initiate the download
              a.click();

              // Clean up the URL object to release resources
              window.URL.revokeObjectURL(url);
              this.InactiveAssetAnalysisexportloading = false;
              this.feedbackerror = null;
            })
            .catch((error) => {
              console.error("Error exporting Excel file:", error);
              this.feedbackerror = "ERROR - exporting Excel file:" + error;
              this.InactiveAssetAnalysisexportloading = false;
            });
        } catch (error) {
          // Handle error
          console.error("Error exporting Excel file:", error);
          this.InactiveAssetAnalysisexportloading = false;
        }
      } catch (error) {
        // Handle error
        console.error("Error exporting Excel file:", error);
        this.InactiveAssetAnalysisexportloading = false;
      }
    },
    }
  };
