import App from './App.vue'
import router from './router'
import { createApp } from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'


const app = createApp(App)
app.use(Vuetify)
app.use(router)

app.mount('#app')