import axios from "axios";

export default {
    methods: {
      closePopup() {
        // Set popupVisible to false to hide the popup
        this.popupVisible = false;
      },
        startInactiveAssetAnalysis() {

            this.InactiveAssetUploadProgress = 20;
           if (this.selectedParameterColumns.length === 0  && this.selectedParameterColumn2.length === 0 && this.selectedParameterColumn3.length === 0) {
              this.popupVisible = true;
              this.popupMessage =
                "Unable to find any of this columns in file (IoT_Enabled_Asset_ID,Serial_Number,serial,serial_no,Serial Number), any one of these columns are mandatory to perform analysis";
            }


            if (this.selectedParameterColumn3.length === 0 && this.selectedfunc.includes("Airvantage report")) {
              this.popupVisible = true;
              this.popupMessage =
                "IMEI Column is mandatory for airvantage report, add required details and reupload file";
                this.InactiveAssetUploadProgress =-1;

            }

            this.InactiveAssetUploadProgress = 30;
            //PREPARE MISSING DATA IN INACTIVEASSET DATAFRAME

              try {
                  axios
              .post("https://fmsnodebackenddev-pcalvs64ua-uc.a.run.app/auth/database/altertable", {
                selectedcolumn: this.selectedParameterColumns,
                tablename: this.table_name_final,
              })
              .then((response) => {
                // Update the history array with the fetched data
                if (response.data.message === "Successalter") {
                  this.performStatusUpdates();
                }
                else{
                  this.InactiveAssetAnalysisProgress = -1;
                }
              }

              );
               } catch (error) {
                // Handle error
                this.InactiveAssetAnalysisProgress = -1;
              }
          },
          taskCompletionHandler() {
            console.log('Inside taskcompletion')
            this.completed_Task++;
            console.log(this.completed_Task)
            console.log(this.selectedfunc.length)
            console.log(this.completed_Task === this.selectedfunc.length)
            if (this.completed_Task === this.selectedfunc.length) {
              // All tasks are completed
              setTimeout(() => {
                console.log("Delayed for 20 second.");
                this.InactiveAssetUploadProgress = 100;
                clearInterval(this.bqinterval);
                clearInterval(this.edinterval);
                clearInterval(this.continterval);
                clearInterval(this.airvantinterval);
                clearInterval(this.edgedevicedatasettypeinterval);
              }, "20000");

            }
          },
          //async function for bigqueryreport updates
          async bigquerystatusupdate() {
            try {
              console.log('Started big query status updates')
              this.bqinterval = setInterval(async () => {
                try {
                  const response = await axios.get(
                    "https://fmsnodebackenddev-pcalvs64ua-uc.a.run.app/auth/database/checklastestupdate/bigqueryreportaudittable",
                    {
                      params: {
                        selectedcolumn: this.selectedfunc,
                        tablename: this.table_name_final, // Include the value as a parameter
                      },
                    }
                  );
                  if (response.data) {
                     console.log(response)

                    for (let i = 0; i < response.data.length; i++) {
                      const slnoToUpdate = response.data[i].fmsidx;
                      const FMS_Cntr_Dataset_Id = `FMS_Cntr_Dataset-${slnoToUpdate}`;
                      const FMS_cntr_Num_Tables_Id = `FMS_cntr_Num_Tables-${slnoToUpdate}`;
                      const FMS_Cntreventtable_Id = `FMS_Cntreventtable-${slnoToUpdate}`;
                      const FMS_CntrMetadatatable_Id = `FMS_CntrMetadatatable-${slnoToUpdate}`;
                      const FMS_CntrEAVTable_Id = `FMS_CntrEAVTable-${slnoToUpdate}`;
                      const FMS_CntrMgatewaytable_Id = `FMS_CntrMgatewaytable-${slnoToUpdate}`;
                      const FMS_CntrTimeseriestable_Id = `FMS_CntrTimeseriestable-${slnoToUpdate}`;
                      const FMS_Cntr_createDate_Id = `FMS_Cntr_createDate-${slnoToUpdate}`;
                      const FMS_Cntr_SinceCreateDate_Id = `FMS_Cntr_SinceCreateDate-${slnoToUpdate}`;
                      const FMS_Cntr_TSlastpopulatedDate_Id = `FMS_Cntr_TSlastpopulatedDate-${slnoToUpdate}`;
                      const FMS_Cntr_TS_sincelastpopulatedDate_Id = `FMS_Cntr_TS_sincelastpopulatedDate-${slnoToUpdate}`;

                      var FMS_Cntr_Dataset_Id_Element = document.getElementById(FMS_Cntr_Dataset_Id);
                      var FMS_cntr_Num_Tables_Id_Element = document.getElementById(FMS_cntr_Num_Tables_Id);
                      var FMS_Cntreventtable_Id_Element = document.getElementById(FMS_Cntreventtable_Id);
                      var FMS_CntrMetadatatable_Id_Element = document.getElementById(FMS_CntrMetadatatable_Id);
                      var FMS_CntrEAVTable_Id_Element = document.getElementById(FMS_CntrEAVTable_Id);
                      var FMS_CntrMgatewaytable_Id_Element = document.getElementById(FMS_CntrMgatewaytable_Id);
                      var FMS_CntrTimeseriestable_Id_Element = document.getElementById(FMS_CntrTimeseriestable_Id);
                      var FMS_Cntr_createDate_Id_Element = document.getElementById(FMS_Cntr_createDate_Id);
                      var FMS_Cntr_SinceCreateDate_Id_Element = document.getElementById(FMS_Cntr_SinceCreateDate_Id);
                      var FMS_Cntr_TSlastpopulatedDate_Id_Element = document.getElementById(FMS_Cntr_TSlastpopulatedDate_Id);
                      var FMS_Cntr_TS_sincelastpopulatedDate_Id_Element = document.getElementById(FMS_Cntr_TS_sincelastpopulatedDate_Id);


                      const setElementTextContent = (element, value) => {
                        if (element) {
                          element.textContent = value;
                        } else {
                          console.warn("Element is null or undefined.");
                        }
                      };

                      // Usage
                      setElementTextContent(
                        FMS_Cntr_Dataset_Id_Element,
                        response.data[i].FMS_Cntr_Dataset
                      );
                      setElementTextContent(
                        FMS_cntr_Num_Tables_Id_Element,
                        response.data[i].FMS_cntr_Num_Tables
                      );
                      setElementTextContent(
                        FMS_Cntreventtable_Id_Element,
                        response.data[i].FMS_Cntreventtable
                      );
                      setElementTextContent(
                        FMS_CntrMetadatatable_Id_Element,
                        response.data[i].FMS_CntrMetadatatable
                      );
                      setElementTextContent(
                        FMS_CntrEAVTable_Id_Element,
                        response.data[i].FMS_CntrEAVTable
                      );
                      setElementTextContent(
                        FMS_CntrMgatewaytable_Id_Element,
                        response.data[i].FMS_CntrMgatewaytable
                      );
                      setElementTextContent(
                        FMS_CntrTimeseriestable_Id_Element,
                        response.data[i].FMS_CntrTimeseriestable
                      );
                      setElementTextContent(
                        FMS_Cntr_createDate_Id_Element,
                        response.data[i].FMS_Cntr_createDate
                      );
                      setElementTextContent(
                        FMS_Cntr_SinceCreateDate_Id_Element,
                        response.data[i].FMS_Cntr_SinceCreateDate
                      );
                      setElementTextContent(
                        FMS_Cntr_TSlastpopulatedDate_Id_Element,
                        response.data[i].FMS_Cntr_TSlastpopulatedDate
                      );
                      setElementTextContent(
                        FMS_Cntr_TS_sincelastpopulatedDate_Id_Element,
                        response.data[i].FMS_Cntr_TS_sincelastpopulatedDate
                      );
                    }
                  }
                } catch (error) {
                  clearInterval(this.bqinterval);

                  console.error(
                    "Error calling Node.js URL checklastestupdate bigqueryreport:",
                    error
                  );
                }
              }, 5000);
              axios
                .post(
                  "https://bigqueryreport-pcalvs64ua-ue.a.run.app/bigqueryreport",
                  {
                    selectedColumn1 : this.selectedParameterColumns,
                    selectedColumn2: this.selectedParameterColumn2,
                    selectedColumn3:this.selectedParameterColumn3,
                    selectedColumn1missingindex: this.iotenablemissing_row_index,
                    selectedColumn2missingindex:this.serialnummissing_row_index,
                    tablename: this.table_name_final,
                  }
                )
                .then(async (response) => {
                  this.InactiveAssetUploadProgress = 50;
                  this.taskCompletionHandler();
                  //this.feedbackerror = null;
                              console.log(response)

                })
                .catch((error) => {
                  clearInterval(this.bqinterval);

                  this.InactiveAssetUploadProgress = -1;
                  if (error.response && error.response.status === 403) {
                    this.feedbackerror = "Error occured while running big query report",error;
                  } else {
                    this.feedbackerror = "Error occured while running big query report",error;
                  }
                              this.taskCompletionHandler();

                });
            } catch (error) {
              // Handle error
              this.InactiveAssetUploadProgress = -1;
              this.InactiveAssetAnalysisfeedback = "Error occured while running big query audit report",error;

            }
          },

          async edgedevicestatusupdate() {
            console.log('Started edgedevicestatusupdate updates')

          try {
            this.edinterval = setInterval(async () => {
              try {

                const response = await axios.get(
                  `https://fmsnodebackenddev-pcalvs64ua-uc.a.run.app/auth/database/checklastestupdate/edgedevicereportaudittable`,
                  {
                    params: {
                      selectedcolumn: this.selectedfunc,
                      tablename: this.table_name_final, // Include the value as a parameter
                    },
                  }
                );
                if (response.data) {
                  console.log(response)

                  for (let i = 0; i < response.data.length; i++) {
                    const slnoToUpdate = response.data[i].fmsidx; // Change this to the desired fmsidx

                    const FMS_Edge_Dataset_Id = `FMS_Edge_Dataset-${slnoToUpdate}`;
                    const FMS_Edge_Num_Tables_Id = `FMS_Edge_Num_Tables-${slnoToUpdate}`;
                    const FMS_EdgeTimeseriestable_Id = `FMS_EdgeTimeseriestable-${slnoToUpdate}`;
                    const FMS_Edgeeventtable_Id = `FMS_Edgeeventtable-${slnoToUpdate}`;
                    const FMS_EdgeMetadatatable_Id = `FMS_EdgeMetadatatable-${slnoToUpdate}`;
                    const FMS_EdgeMgatewaytable_Id = `FMS_EdgeMgatewaytable-${slnoToUpdate}`;
                    const FMS_EdgeEAVTable_Id = `FMS_EdgeEAVTable-${slnoToUpdate}`;
                    const FMS_Edge_createDate_Id = `FMS_Edge_createDate-${slnoToUpdate}`;
                    const FMS_Edge_DaysSinceCreateDate_Id = `FMS_Edge_DaysSinceCreateDate-${slnoToUpdate}`;
                    const FMS_Edge_TSlastpopulatedDate_Id = `FMS_Edge_TSlastpopulatedDate-${slnoToUpdate}`;
                    const FMS_Edge_TS_sincelastpopulatedDate_Id = `FMS_Edge_TS_sincelastpopulatedDate-${slnoToUpdate}`;


                    var FMS_Edge_Dataset_Id_Element = document.getElementById(FMS_Edge_Dataset_Id);
                    var FMS_Edge_Num_Tables_Id_Element = document.getElementById(FMS_Edge_Num_Tables_Id);
                    var FMS_EdgeTimeseriestable_Id_Element = document.getElementById(FMS_EdgeTimeseriestable_Id);
                    var FMS_Edgeeventtable_Id_Element = document.getElementById(FMS_Edgeeventtable_Id);
                    var FMS_EdgeMetadatatable_Id_Element = document.getElementById(FMS_EdgeMetadatatable_Id);
                    var FMS_EdgeMgatewaytable_Id_Element = document.getElementById(FMS_EdgeMgatewaytable_Id);
                    var FMS_EdgeEAVTable_Id_Element = document.getElementById(FMS_EdgeEAVTable_Id);
                    var FMS_Edge_createDate_Id_Element = document.getElementById(FMS_Edge_createDate_Id);
                    var FMS_Edge_DaysSinceCreateDate_Id_Element = document.getElementById(FMS_Edge_DaysSinceCreateDate_Id);
                    var FMS_Edge_TSlastpopulatedDate_Id_Element = document.getElementById(FMS_Edge_TSlastpopulatedDate_Id);
                    var FMS_Edge_TS_sincelastpopulatedDate_Id_Element = document.getElementById(FMS_Edge_TS_sincelastpopulatedDate_Id);



                    const setElementTextContent = (element, value) => {
                      if (element) {
                        element.textContent = value;
                      } else {
                        console.warn("Element is null or undefined.");
                      }
                    };

                    setElementTextContent(
                      FMS_Edge_Dataset_Id_Element,
                      response.data[i].FMS_Edge_Dataset
                    );
                          setElementTextContent(
                      FMS_Edge_Num_Tables_Id_Element,
                      response.data[i].FMS_Edge_Num_Tables
                    );    setElementTextContent(
                      FMS_EdgeTimeseriestable_Id_Element,
                      response.data[i].FMS_EdgeTimeseriestable
                    );    setElementTextContent(
                      FMS_Edgeeventtable_Id_Element,
                      response.data[i].FMS_Edgeeventtable
                    );    setElementTextContent(
                      FMS_EdgeMetadatatable_Id_Element,
                      response.data[i].FMS_EdgeMetadatatable
                    );    setElementTextContent(
                      FMS_EdgeMgatewaytable_Id_Element,
                      response.data[i].FMS_EdgeMgatewaytable
                    );    setElementTextContent(
                      FMS_EdgeEAVTable_Id_Element,
                      response.data[i].FMS_EdgeEAVTable
                    );    setElementTextContent(
                      FMS_Edge_createDate_Id_Element,
                      response.data[i].FMS_Edge_createDate
                    );    setElementTextContent(
                      FMS_Edge_DaysSinceCreateDate_Id_Element,
                      response.data[i].FMS_Edge_DaysSinceCreateDate
                    );    setElementTextContent(
                      FMS_Edge_TSlastpopulatedDate_Id_Element,
                      response.data[i].FMS_Edge_TSlastpopulatedDate
                    );    setElementTextContent(
                      FMS_Edge_TS_sincelastpopulatedDate_Id_Element,
                      response.data[i].FMS_Edge_TS_sincelastpopulatedDate
                    );

                  }
                }
              } catch (error) {
                clearInterval(this.edinterval);

                console.error(
                  "Error calling Node.js URL checklastestupdate:",
                  error
                );
              }
            }, 5000);
            axios
              .post(
                `https://edgedevicereport-pcalvs64ua-ue.a.run.app/edgedevicereport`,
                {
                    selectedColumn1 : this.selectedParameterColumns,
                  selectedColumn2: this.selectedParameterColumn2,
                  selectedColumn3:this.selectedParameterColumn3,
                  selectedColumn1missingindex: this.iotenablemissing_row_index,
                  selectedColumn2missingindex:this.serialnummissing_row_index,
                  tablename: this.table_name_final,
                }
              )
              .then(async (response) => {
                console.log("Edge device api call respone")

                console.log(response)
                this.InactiveAssetUploadProgress = 60;
                this.taskCompletionHandler();
                //this.feedbackerror = null;
              })
              .catch((error) => {
                clearInterval(this.edinterval);
                this.InactiveAssetUploadProgress = -1;
                if (error.response && error.response.status === 403) {
                  this.feedbackerror = `${this.feedbackerror ? this.feedbackerror : ''} Error occured while running edge device report other report still running wait till 100% completion`,error;
                } else {
                  this.feedbackerror = `${this.feedbackerror ? this.feedbackerror : ''} Error occured while running edge device report other report still running wait till 100% completion`,error;
                }
                            this.taskCompletionHandler();

              });
          } catch (error) {
            // Handle error
            this.InactiveAssetUploadProgress = -1;

            this.InactiveAssetAnalysisfeedback = "Error occured while running edge device audit report",error;


          }
        },

          async airvantagestatusupdate() {
                    console.log('Started airvantagestatusupdate updates')

            try {
              this.airvantinterval = setInterval(async () => {
                try {

                  const response = await axios.get(
                    "https://fmsnodebackenddev-pcalvs64ua-uc.a.run.app/auth/database/checklastestupdate/airvantageaudittable",
                    {
                      params: {
                        selectedcolumn: this.selectedfunc,
                        tablename: this.table_name_final, // Include the value as a parameter
                      },
                    }
                  );


                  if (response.data) {
                    for (let i = 0; i < response.data.length; i++) {
                      const slnoToUpdate = response.data[i].fmsidx; // Change this to the desired slno

                      const FMS_Activationstatus_Id = `FMS_Activationstatus-${slnoToUpdate}`;
                      const FMS_SIMtechnology_Id = `FMS_SIMtechnology-${slnoToUpdate}`;
                      const FMS_Offer_Id = `FMS_Offer-${slnoToUpdate}`;
                      const FMS_Signal_strength_Id = `FMS_Signal_strength-${slnoToUpdate}`;
                      const FMS_LASTCommunicationDate_Id = `FMS_LASTCommunicationDate-${slnoToUpdate}`;
                      const FMS_productRefName_Id = `FMS_productRefName-${slnoToUpdate}`;

                      var FMS_Activationstatus_Id_Element =document.getElementById(FMS_Activationstatus_Id);
                      var FMS_SIMtechnology_Id_Element = document.getElementById(FMS_SIMtechnology_Id);
                      var FMS_Offer_Id_Element = document.getElementById(FMS_Offer_Id);
                      var FMS_Signal_strength_Id_Element = document.getElementById(FMS_Signal_strength_Id);
                      var FMS_LASTCommunicationDate_Id_Element = document.getElementById(FMS_LASTCommunicationDate_Id);
                      var FMS_productRefName_Id_Element = document.getElementById(FMS_productRefName_Id);

                      const setElementTextContent = (element, value) => {
                        if (element) {
                          element.textContent = value;
                        } else {
                          console.warn("Element is null or undefined.");
                        }
                      };

                      setElementTextContent(
                        FMS_Activationstatus_Id_Element,
                        response.data[i].FMS_Activationstatus
                      );
                      setElementTextContent(
                        FMS_SIMtechnology_Id_Element,
                        response.data[i].FMS_SIMtechnology
                      );
                      setElementTextContent(
                        FMS_Offer_Id_Element,
                        response.data[i].FMS_Offer
                      );
                                      setElementTextContent(
                        FMS_Signal_strength_Id_Element,
                        response.data[i].FMS_Signal_strength
                      );
                      setElementTextContent(
                        FMS_LASTCommunicationDate_Id_Element,
                        response.data[i].FMS_LASTCommunicationDate
                      );
                      setElementTextContent(
                        FMS_productRefName_Id_Element,
                        response.data[i].FMS_productRefName
                      );
                    }
                  }
                } catch (error) {
                  clearInterval(this.airvantinterval);

                  console.error(
                    "Error calling Node.js URL checklastestupdate:",
                    error
                  );
                }
              }, 5000);
              axios
                .post(
                  "https://airvantageassetinfo-pcalvs64ua-ue.a.run.app/airvantageassetinfo",
                  {

                    columnname:this.selectedParameterColumn3,
                    missing_index_row: this.imeimissing_row_index,
                    tablename: this.table_name_final,
                  }
                )
                .then(async (response) => {
                   this.InactiveAssetUploadProgress = 80;
                  console.log(response)

                  this.taskCompletionHandler();
                 // this.feedbackerror = null;
                })
                .catch((error) => {
                  clearInterval(this.airvantinterval);
                  this.InactiveAssetUploadProgress = -1;
                  if (error.response && error.response.status === 403) {
                            this.feedbackerror = "Error occured while running airvantage report",error;

                  } else {
                            this.feedbackerror = "Error occured while running airvantage report",error;
                  }
                              this.taskCompletionHandler();

                });
            } catch (error) {
              // Handle error
              this.InactiveAssetUploadProgress = -1;

              this.InactiveAssetAnalysisfeedback =
                "Error during airvantageaudittable analysis";
            }
          },
          async controllerstatusupdate() {
            console.log('Started controllerstatusupdate updates')

try {
this.continterval = setInterval(async () => {
  try {

    const response = await axios.get(
      `https://fmsnodebackenddev-pcalvs64ua-uc.a.run.app/auth/database/checklastestupdate/controllerreportaudittable`,
      {
        params: {
          selectedcolumn: this.selectedfunc,
          tablename: this.table_name_final, // Include the value as a parameter
        },
      }
    );
    if (response.data) {
      for (let i = 0; i < response.data.length; i++) {
        const slnoToUpdate = response.data[i].fmsidx; // Change this to the desired slno

        const FMS_Cntrrep_Dataset_Id = `FMS_Cntrrep_Dataset-${slnoToUpdate}`;
        const FMS_Cntrrep_Communication_loss_Id = `FMS_Cntrrep_Communication_loss-${slnoToUpdate}`;
        const FMS_Cntrrep_NoiseFault_Id = `FMS_Cntrrep_NoiseFault-${slnoToUpdate}`;
        const FMS_Cntrrep_Num_rows_Edge_TS_last7days_Id = `FMS_Cntrrep_Num_rows_Edge_TS_last7days-${slnoToUpdate}`;
        const FMS_Cntrrep_Num_rows_Cntr_TS_last7days_Id = `FMS_Cntrrep_Num_rows_Cntr_TS_last7days-${slnoToUpdate}`;
        const FMS_Cntrrep_edgedatsettype_Id = `FMS_Cntrrep_edgedatsettype-${slnoToUpdate}`;

        var FMS_Cntrrep_Dataset_Id_Element = document.getElementById(FMS_Cntrrep_Dataset_Id);
        var FMS_Cntrrep_Communication_loss_Id_Element = document.getElementById(FMS_Cntrrep_Communication_loss_Id);
        var FMS_Cntrrep_NoiseFault_Id_Element = document.getElementById(FMS_Cntrrep_NoiseFault_Id);
        var FMS_Cntrrep_Num_rows_Edge_TS_last7days_Id_Element = document.getElementById(FMS_Cntrrep_Num_rows_Edge_TS_last7days_Id);
        var FMS_Cntrrep_Num_rows_Cntr_TS_last7days_Id_Element = document.getElementById(FMS_Cntrrep_Num_rows_Cntr_TS_last7days_Id);
        var FMS_Cntrrep_edgedatsettype_Id_Element = document.getElementById(FMS_Cntrrep_edgedatsettype_Id);


        const setElementTextContent = (element, value) => {
          if (element) {
            element.textContent = value;
          } else {
            console.warn("Element is null or undefined.");
          }
        };

        setElementTextContent(
          FMS_Cntrrep_Dataset_Id_Element,
          response.data[i].FMS_Cntrrep_Dataset
        );
                        setElementTextContent(
          FMS_Cntrrep_Communication_loss_Id_Element,
          response.data[i].FMS_Cntrrep_Communication_loss
        );
                        setElementTextContent(
          FMS_Cntrrep_NoiseFault_Id_Element,
          response.data[i].FMS_Cntrrep_NoiseFault
        );
                        setElementTextContent(
          FMS_Cntrrep_Num_rows_Edge_TS_last7days_Id_Element,
          response.data[i].FMS_Cntrrep_Num_rows_Edge_TS_last7days
        );
                        setElementTextContent(
          FMS_Cntrrep_Num_rows_Cntr_TS_last7days_Id_Element,
          response.data[i].FMS_Cntrrep_Num_rows_Cntr_TS_last7days
        );
         setElementTextContent(
          FMS_Cntrrep_edgedatsettype_Id_Element,
          response.data[i].FMS_Cntrrep_edgedatsettype
        );
      }
    }
  } catch (error) {
    clearInterval(this.continterval);

    console.error("Error calling Node.js URL controllerreport:", error);
  }
}, 5000);
axios
  .post(
    `https://controllerreport-pcalvs64ua-ue.a.run.app/controllerreport`,
    {
        selectedColumn1 : this.selectedParameterColumns,
      selectedColumn2: this.selectedParameterColumn2,
      selectedColumn3:this.selectedParameterColumn3,
      selectedColumn1missingindex: this.iotenablemissing_row_index,
      selectedColumn2missingindex:this.serialnummissing_row_index,
      tablename: this.table_name_final,
    }
  )
  .then(async (response) => {
                console.log("Response from controller api",response)

    this.InactiveAssetUploadProgress = 70;
    console.log(response)

    this.taskCompletionHandler();
    this.feedbackerror = null;
  })
  .catch((error) => {
    clearInterval(this.continterval);
    this.InactiveAssetUploadProgress = -1;
    if (error.response && error.response.status === 403) {
        this.feedbackerror = `${this.feedbackerror ? this.feedbackerror : ''} Error occured while running controller report other report still running wait till 100% completion`,error;
    } else {
       this.feedbackerror = `${this.feedbackerror ? this.feedbackerror : ''} Error occured while running controller report other report still running wait till 100% completion`,error;
    }
                this.taskCompletionHandler();

  });
} catch (error) {
// Handle error
this.InactiveAssetUploadProgress = -1;
this.InactiveAssetAnalysisfeedback =
  "Error during controller report audit analysis";
}
},
async edgedevicedatasettypestatusupdate() {
      console.log('Started edgedevicedatasettypestatusupdate updates')

try {
this.edgedevicedatasettypeinterval = setInterval(async () => {
  try {

    const response = await axios.get(
      "https://fmsnodebackenddev-pcalvs64ua-uc.a.run.app/auth/database/checklastestupdate/edgedevicedatasetaudittable",
      {
        params: {
          selectedcolumn: this.selectedfunc,
          tablename: this.table_name_final, // Include the value as a parameter
        },
      }
    );


    if (response.data) {
      for (let i = 0; i < response.data.length; i++) {
        const slnoToUpdate = response.data[i].fmsidx; // Change this to the desired slno

        const FMS_Edge_Datasettype_Id = `FMS_Edge_Datasettype-${slnoToUpdate}`;
        const FMS_Edge_Datasettypecompare_Id = `FMS_Edge_Datasettypecompare-${slnoToUpdate}`;

        var FMS_Edge_Datasettype_Id_Element = document.getElementById(FMS_Edge_Datasettype_Id);
        var FMS_Edge_Datasettypecompare_Id_Element = document.getElementById(FMS_Edge_Datasettypecompare_Id);

        const setElementTextContent = (element, value) => {
          if (element) {
            element.textContent = value;
          } else {
            console.warn("Element is null or undefined.");
          }
        };

                 setElementTextContent(
          FMS_Edge_Datasettype_Id_Element,
          response.data[i].FMS_Edge_Datasettype
        );
         setElementTextContent(
          FMS_Edge_Datasettypecompare_Id_Element,
          response.data[i].FMS_Edge_Datasettypecompare
        );
      }
    }
  } catch (error) {
    clearInterval(this.edgedevicedatasettypeinterval);

    console.error(
      "Error calling Node.js URL checklastestupdate:",
      error
    );
  }
}, 5000);
axios
  .post(
    "https://edgedevicetypedev-pcalvs64ua-ue.a.run.app/edgedevicetype",
    {
        selectedColumn1 : this.selectedParameterColumns,
      selectedColumn2: this.selectedParameterColumn2,
      selectedColumn3:this.selectedParameterColumn3,
      selectedColumn1missingindex: this.iotenablemissing_row_index,
      selectedColumn2missingindex:this.serialnummissing_row_index,
      tablename: this.table_name_final,
    }
  )
  .then(async (response) => {
     this.InactiveAssetUploadProgress = 80;
    console.log(response)

    this.taskCompletionHandler();
    this.feedbackerror = null;
  })
  .catch((error) => {
    clearInterval(this.edgedevicedatasettypeinterval);
    this.InactiveAssetUploadProgress = -1;
    if (error.response && error.response.status === 403) {
        this.feedbackerror = `${this.feedbackerror ? this.feedbackerror : ''} Error occured while running edgedevicetype report other report still running wait till 100% completion`,error;
    } else {
        this.feedbackerror = `${this.feedbackerror ? this.feedbackerror : ''} Error occured while running edgedevicetype report other report still running wait till 100% completion`,error;
    }
    this.taskCompletionHandler();

  });
} catch (error) {
// Handle error
this.InactiveAssetUploadProgress = -1;
this.InactiveAssetAnalysisfeedback =
  "Error during edgedevicetype audittable analysis";
}
},
          async performStatusUpdates() {
            const statusupdatesByFunction = {
              "Big query report": "bigquerystatusupdate",
              "Edge device report": "edgedevicestatusupdate",
              "Controller report": "controllerstatusupdate",
              "Airvantage report": "airvantagestatusupdate",
              "Edge devicetype report":"edgedevicedatasettypestatusupdate",

            };
            this.InactiveAssetUploadProgress = 40;

            try {
              // Use Promise.all to wait for all asynchronous tasks to complete
              await Promise.all(
                this.selectedfunc.map(async (selectionfunc) => {
                  const selectedStatusUpdateFunctionName =
                    statusupdatesByFunction[selectionfunc];

                  if (selectedStatusUpdateFunctionName) {
                    await this[selectedStatusUpdateFunctionName]();
                  } else {
                    console.warn(
                      `No status update function found for ${selectionfunc}`
                    );
                  }
                })
              );
            } catch (error) {
              console.error(`Error updating status: ${error.message}`);
            }
          },

    }
  };
