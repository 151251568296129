<!-- Single file component for Analysis home page -->
<!-- template : Select option of analysis and history -->

<template>
  <div>

      <div v-if="!showHistoryData">
        <!-- Display history table when the footer is open -->
        <table class="analysis-home-history-table" v-if="history">
          <thead>
            <tr>
              <th>Created_On</th>
              <th>Filename</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(historyItem, index) in history" :key="index">
              <td>{{ historyItem.date }}</td>
              <td>{{ historyItem.filename }}</td>
              <td>
                <button @click="viewHistory(index)">
                  <span v-if="viewhistoryloading && selectedIndex === index">
                    <i class="fas fa-spinner fa-spin"></i> Loading...
                  </span>
                  <span v-else>View</span></button
                >&nbsp;&nbsp;
                <button @click="downloadHistory(index)">
                  <span v-if="downloadhistoryloading && selectedIndex === index">
                    <i class="fas fa-spinner fa-spin"></i> Loading...
                  </span>
                  <span v-else>Download</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

  </div>
</template>
<script>
import axios from "axios";

export default {

  data() {
    return {
      isOptionsDropdownOpen:false,
      selectedOption:null,
      showHistoryData:false,
      viewhistoryloading: false,
      downloadhistoryloading: false,
      history: [],
      parentData: null,

    }
  },
    created() {
    // Fetch table names when the component is created (document loaded)
    this.fetchTableNames();
  },
  methods:{

 // Fetch table names from the backend
    fetchTableNames() {
      // Make an API request to fetch table names
      const logguser = this.$route.params.username
      console.log('loggusername check---',logguser)
      console.log(logguser)
      axios
        .get('https://fmsnodebackenddev-pcalvs64ua-uc.a.run.app/auth/database/tableNames/',{
            params: {
            loggeduser:  this.$route.params.username, // Include the value as a parameter
          },
        })
        .then((response) => {
          // Update the history array with the fetched data
          console.log(response.data.CREATE_TIME);
          this.history = response.data.map((results, index) => ({
            date: results.CREATE_TIME.split('.')[0].replace('T'," "), // You can customize the date as needed
            filename: results.TABLE_NAME, // Use the table name as the filename
            id: index, // You can assign a unique ID if needed
          }));
          console.log("Tables names in db");
          console.log(this.history);
        })
        .catch((error) => {
          console.error("Error fetching table names:", error);
        });
    },
     viewHistory(index) {
      // Get the table name from the selected history item
      const tableName = this.history[index].filename;
      this.selectedIndex = index;

      // Set isLoading to true to show the loading indicator
      this.viewhistoryloading = true;

      // Make an HTTP GET request to fetch data based on the table name
      axios
        .get(`https://fmsnodebackenddev-pcalvs64ua-uc.a.run.app/auth/database/tableData/${tableName}`)
        .then((response) => {
          // Handle the server response here
          const { data, headers } = response.data;

          // Open a new browser tab or window and pass the data
          const newTab = window.open("", "_blank");
          newTab.document.write(`
        <html>
          <head>
            <title>Table Data</title>
            <style>
              table {
                border-collapse: collapse;
                width: 100%;
              }

              th, td {
                border: 1px solid #dddddd;
                text-align: left;
                padding: 8px;
              }

              th {
                background-color: #f2f2f2;
              }

              tr:nth-child(even) {
                background-color: #f2f2f2;
              }
            </style>
          </head>
          <body>
            <h1>Table Data for ${tableName}</h1>
            <table>
              <thead>
                <tr>
                  ${headers.map((header) => `<th>${header}</th>`).join("")}
                </tr>
              </thead>
              <tbody>
                ${data
                  .map(
                    (row) =>
                      `<tr>${headers
                        .map((header) => `<td>${row[header]}</td>`)
                        .join("")}</tr>`
                  )
                  .join("")}
              </tbody>
            </table>
          </body>
        </html>
      `);
          newTab.document.close();

          // Set isLoading to false once data is received
          this.viewhistoryloading = false;
          this.selectedIndex = null;
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error:", error);
          // Set isLoading to false even if there's an error
          this.viewhistoryloading = false;
          this.selectedIndex = null;
        });
    },

    // Handle "Download" button click (you can implement this as needed)
    async downloadHistory(index) {
      // Implement download action based on your requirements
      console.log("Download history:", this.history[index]);
      this.selectedIndex = index;
      this.downloadhistoryloading = true;

      try {
        const tableName = this.history[index].filename;
        // Make a GET request to the Node.js endpoint
        const response = await axios.get(
          `https://fmsnodebackenddev-pcalvs64ua-uc.a.run.app/auth/database/download/${tableName}`,
          {
            responseType: "blob", // Receive response as a blob (binary data)
          }
        );
        console.log(response.data);
        // Create a Blob and initiate the download
        const blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${tableName}.xlsx`;
        a.click();

        this.downloadhistoryloading = false;
        this.selectedIndex = null;
      } catch (error) {
        console.error("Error downloadhistoryloading data:", error);
        this.downloadhistoryloading = false;
        this.selectedIndex = null;
      }
    }
  }
}
</script>


<style scoped>
.analysis-home-main-div
{
display: flex;
align-items: flex-start;

}
.rightside{
  display: flex;
}

.analysis-home-multi-div {

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 9px;
  margin-right: 2%;
  margin-left: 1%;
  margin-top: 1%;
  float: left; /* Float it to the left side */
  z-index: 2;
  max-height: 75px; /* Set a maximum height for the div */
  background-color: #d3f0f0; /* Set the background color */
}
.analysis-home-selction-div{
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.analysis-home-selection {
  flex-grow: 1;
  text-align: left;
  margin-right: 10px;
  border: 1px solid #0d45a5;
}
.analysis-home-selection-header {
  background-color: #0d45a5;
  padding: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: auto;
  color: white;
}
.analysis-home-arrow {
  font-size: 14px; /* Adjust the size as needed */
  margin-left: 5px; /* Add spacing between text and arrow */
}
.analysis-home-selection-options {
  border: 1px solid #ccc;
  border-top: none;
  max-height: 350px;
  overflow: auto;
  position: absolute;
  background-color: #fff;
  top: 100%;
  z-index: 5;
  width: auto; /* Use 'auto' to make it as wide as the content */
  max-width: 400px; /* Set a maximum width to avoid excessive width */
  white-space: nowrap; /* Allow text to wrap */
}
.analysis-home-selection-options label {
  display: block;
  padding: 5px;
  cursor: pointer;
  z-index: 6;
  width: 100%;
}

.analysis-home-selection-options label:hover {
  background-color: #f0f0f0;
}
.analysis-home-history-div {
  border: 1px solid #ddd;
  margin: 10px;
  padding: 10px;
  background-color: #f7f7f7;
  border-radius: 5px;
  display: flex;

}
.analysis-home-history-toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 5px;
}

.analysis-home-history-toggle span {
  margin-right: 5px;
  transition: transform 0.2s;
}

.analysis-home-history-toggle.collapsed span {
  transform: rotate(90deg);
}

.analysis-home-history-table {
  border-collapse: collapse;
  /* You can set a specific width or use a percentage value based on your design */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-collapse: collapse;
  table-layout: fixed; /* Ensure a fixed layout */
  display: inline-block;
  white-space: nowrap;
        flex: 1; /* Let the table grow to take available space */
  margin-left: 5%; /* Adjust the margin as needed */
  margin-top: 2%; /* Adjust the margin as needed */
  font-family: 'Times New Roman', Times, serif;
  font-size: 15px;


}

.analysis-home-history-table table {
  border-collapse: collapse;

}

.analysis-home-history-table th,
.analysis-home-history-table td {
  border: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  width: 100%;
  overflow-wrap: break-word;

}

.analysis-home-history-table th {
  background-color: #add5bf;
  font-weight: bold;
}

.analysis-home-history-table tbody tr:hover {
  background-color: #f0f0f0;
}
</style>