<template>
  <div>
    <div class="head"></div>
    <div>
      <div v-for="(form, index) in forms" :key="index" class="form-container">
        <div class="form-field">
          <select
            :id="`field1-${index}`"
            v-model="form.fields[0].value"
            @change="handleDropdownChange(index, 0)"
            :class="{
              'selected-option':
                form.fields[0].value === form.selectedOptions[0],
            }"
          >
            <option
              v-for="(option, optionIndex) in filtervalue"
              :key="optionIndex"
            >
              {{ option }}
            </option>
          </select>
        </div>

        <div class="form-field">
          <select
            :id="`field2-${index}`"
            v-model="form.fields[1].value"
            @change="handleDropdownChange(index, 1)"
            :class="{
              'selected-option':
                form.fields[1].value === form.selectedOptions[1],
            }"
          >
            <option
              v-for="(option, optionIndex) in comaprisionoptions"
              :key="optionIndex"
            >
              {{ option }}
            </option>
          </select>
        </div>

        <div class="form-field">
          <input
            :id="`field4-${index}`"
            v-model="form.fields[3].value"
            type="text"
            @input="handleChange(index, 3)"
          />
        </div>
        <div class="form-field">
          <select
            :id="`field3-${index}`"
            v-model="form.fields[2].value"
            @change="handleDropdownChange(index, 2)"
            :class="{
              'selected-option':
                form.fields[2].value === form.selectedOptions[2],
            }"
          >
            <option
              v-for="(option, optionIndex) in logicaloperatorvalue"
              :key="optionIndex"
            >
              {{ option }}
            </option>
          </select>
        </div>

        <button
          class="btn-remove"
          @click="removeForm(index)"
          v-if="forms.length > 1"
        >
          -
        </button>
        <button
          class="btn-add"
          @click="addForm"
          v-if="index === forms.length - 1"
        >
          +
        </button>
      </div>
      <button @click="fetch_Data_from_Airvantage" class="fetch-button">
        Fetch Data
      </button>
    </div>
    <div class="simmangementunderline-grey"></div>

    <div class="simmangementheader-right">
      <div v-if="simmangementloading">Loading...</div>
        <button  v-else @click="simmangement_data_modal_exportToExcel">Export</button>
    </div>
    <div>
    <table class="simmangement-data-table" id="simmangement-data-table">
      <thead>
        <tr>
          <!-- Moved "Select" column to the beginning -->
          <th v-for="(value, key) in this.simmangementHeaders" :key="key">
            {{ value }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in simmangementRows" :key="index">
          <td v-for="(value, key) in row" :key="key">{{ value }}</td>
        </tr>
      </tbody>
    </table>
    </div>
  </div>
</template>

<script>
import {utils } from "xlsx";
import axios from "axios";

export default {
  data() {
    return {
    simmangementloading:false,
      filtervalue: [
        "% of bundle",
        "Activation",
        "Alert State",
        "Application Name",
        "Application Revision",
        "Application UID",
        "Certificate Expiration",
        "Comm. Status",
        "Configuration Status",
        "Configuration Type",
        "Creation",
        "Current Operator",
        "Ec/Io",
        "eID",
        "Failover Status",
        "Firmware Name",
        "Firmware Revision",
        "Firmware Type",
        "Form Factor",
        "Gateway End Date",
        "Gateway Type",
        "Has eID",
        "ICCID/ESN",
        "IMEI/ESN",
        "IMSI/MIN",
        "IP Address",
        "Labels",
        "Last Applied Template",
        "Last Comm.",
        "Last Comm. State Change",
        "MAC Address",
        "Metadata",
        "Mobile Number",
        "Name",
        "Offer",
        "Offer Not",
        "Operator",
        "Order ID",
        "Product Ref. Name",
        "Provisioning Option",
        "Provisioning Option Not",
        "Radio Module",
        "RMFW",
        "Roaming Status",
        "RSRP",
        "RSRQ",
        "RSSI",
        "Serial Number",
        "Sign Mode Capacity",
        "Signal Strength",
        "SIM Applet Version",
        "SIM ICCID",
        "SIM Technology",
        "SNR",
        "State",
        "Subscription End Date",
        "Sync. Status",
        "System Type",
        "System UID",
        "Technology",
      ],
      comaprisionoptions: [
        "Before",
        "After",
        "Equals to",
        "Not equals to",
        "inculde",
        "not include",
      ],
      logicaloperatorvalue: ["Select","and", "or"],
      forms: [
        {
          fields: [
            { value: "Application Revision" },
            { value: "Before" },
            { value: "Select" },
            { value: "" },
          ],
          selectedOptions: ["", "", ""],
        },
      ],
      simmangementHeaders: [
      ],
      simmangementRows: [

      ],
      simmangement_table_name_final:'Airvantage_default_table_export.xlsx'
    };
  },
  methods: {
    fetch_Data_from_Airvantage() {
      const selectedValues = this.forms.map((form) =>
        form.fields.map((field) => field.value)
      );
      console.log("selectedValues")
      let airvantage_json_list = []
      selectedValues.forEach((item) => {
            airvantage_json_list.push({ "field_name": item[0], "evaluation": item[1],"value": item[2], "logic": item[3] });
          });

          console.log("airvantage_json_list",airvantage_json_list)
      this.simmangementHeaders=[  "Name",
        "Labels",
        "SerialNumber",
        "ICCID",
        "MSISDN/MDN",
        "Offer",
        "MTDUsage",
        "Percent_of_bundle",
        "Communication_Status",
        "Last_Communication_Status",
        "Activation_Status",
        "Product_Reference",
        "Firmware",
        "IMEI",
        ]
      this.simmangementRows= [
        {
          Name: "354723090497032",
          Labels: "4G_production",
          SerialNumber: "4L239720151210",
          ICCID: "'89148000007772408080'",
          "MSISDN/MDN": "",
          Offer: " Verizon -50MB- United States ",
          MTDUsage: "323131000",
          "Percent of bundle": "None",
          commStatus: "OK",
          lastCommDate: "2023-12-13 16:01:17.544000",
          ActivationStatus: "ACTIVE",
          ProductReference: "",
          Firmware: "FX30(WP7702)_R15.0.5.002,iconn.3.2.25",
          IMEI: "354723090497032",
        },
           {
          Name: "354723090497032",
          Labels: "4G_production",
          SerialNumber: "4L239720151210",
          ICCID: "'89148000007772408080'",
          "MSISDN/MDN": "",
          Offer: " Verizon -50MB- United States ",
          MTDUsage: "323131000",
          "Percent of bundle": "None",
          commStatus: "OK",
          lastCommDate: "2023-12-13 16:01:17.544000",
          ActivationStatus: "ACTIVE",
          ProductReference: "",
          Firmware: "FX30(WP7702)_R15.0.5.002,iconn.3.2.25",
          IMEI: "354723090497032",
        },
           {
          Name: "354723090497032",
          Labels: "4G_production",
          SerialNumber: "4L239720151210",
          ICCID: "'89148000007772408080'",
          "MSISDN/MDN": "",
          Offer: " Verizon -50MB- United States ",
          MTDUsage: "323131000",
          "Percent of bundle": "None",
          commStatus: "OK",
          lastCommDate: "2023-12-13 16:01:17.544000",
          ActivationStatus: "ACTIVE",
          ProductReference: "",
          Firmware: "FX30(WP7702)_R15.0.5.002,iconn.3.2.25",
          IMEI: "354723090497032",
        }
      ],
      console.log("Selected Values:", selectedValues);
    },
    handleDropdownChange(formIndex, fieldIndex) {
      this.forms[formIndex].selectedOptions[fieldIndex] =
        this.forms[formIndex].fields[fieldIndex].value;
    },
    handleChange(formIndex, fieldIndex) {
      this.forms[formIndex].selectedOptions[fieldIndex] =
        this.forms[formIndex].fields[fieldIndex].value;
    },
    addForm() {
      this.forms.push({
        fields: [
          { value: "Application Revision" },
          { value: "Before" },
          { value: "Select" },
          { value: "" },
        ],
        selectedOptions: ["", "", ""],
      });
    },
    removeForm(index) {
      this.forms.splice(index, 1);
    },
  simmangement_data_modal_exportToExcel(){
    console.log('simmangement_exportToExcel')
          this.simmangementloading = true;
      try {
        const table = document.getElementById("simmangement-data-table");
        var opts = {
          raw: true,
        };
        const table_Sheet_Data = utils.table_to_sheet(table, opts);
        // Convert the worksheet data to JSON format
        const jsonData = utils.sheet_to_json(table_Sheet_Data, { header: 1 });
        // Define the headers and data to be sent to the backend
        const headers = jsonData[0]; // Assuming the first row contains headers
        const data = jsonData.slice(1); // Remove the first row (headers)

        // Define the request payload
        const payload = {
          headers: headers,
          data: data,
        };

        // Send a POST request to the backend to generate and download the Excel file
        try {
          axios
            .post("http://localhost:8282/auth/exportfunction/", payload, {
              responseType: "blob", // Receive response as a blob (binary data)
            })
            .then((response) => {
              // Create a blob URL for the response data
              const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });
              const url = window.URL.createObjectURL(blob);

              // Create a link element to trigger the download
              const a = document.createElement("a");
              a.href = url;
              a.download = `${this.simmangement_table_name_final}.xlsx`;

              // Trigger a click event to initiate the download
              a.click();

              // Clean up the URL object to release resources
              window.URL.revokeObjectURL(url);
              this.simmangementloading = false;
              //this.feedbackerror = null;
            })
            .catch((error) => {
              console.error("Error exporting Excel file:", error);
              //this.feedbackerror = "ERROR - exporting Excel file:" + error;
              this.simmangementloading = false;
            });
        } catch (error) {
          // Handle error
          console.error("Error exporting Excel file:", error);
          this.simmangementloading = false;
        }
      } catch (error) {
        // Handle error
        console.error("Error exporting Excel file:", error);
        this.simmangementloading = false;
      }

  },
  },

};
</script>

<style scoped>
.head {
  margin-top: 0.5%;
  margin-bottom: 0.5%;
  margin-left: 0.5%;
}

.form-container {
  display: flex;
  margin-left: 0.5%;
}

.form-field {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 4px;
  color: #333; /* Dark gray text color */
}

select,
input {
  border: 1px solid #807a7a;
  border-radius: 4px;
  background-color: #fff; /* White background */
  color: #555; /* Darker gray text color */
}

.selected-option {
  background-color: #0696ce;
  color: white;
  padding-left: 2%;
}

.btn-add,
.btn-remove {
  cursor: pointer;
  font-size: 1.5em;
  margin-left: 8px;
  color: rgb(8, 8, 8);
  border: none;
  border-radius: 50%;
}

.btn-remove {
  background-color: #fffdfd; /* Red for remove */
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
    table-layout: fixed; /* Ensure a fixed layout */

}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  font-size: 10px;
    overflow-wrap: break-word; /* Allow long words to break */

}
.simmangementunderline-grey {
  border-bottom: 1px solid #ccc; /* Add a grey border at the bottom */
  width: 100%; /* Ensure the underline extends to full width */
}
.simmangementheader-right button {
  margin-top: 1%;
  display: inline-block;
  padding: 5px 10px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  color: #fff;
  background-color: #4caf50; /* Green background color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  transition: transform 0.3s; /* Add a smooth transition effect */
  float: right;
  margin-right: 2%;
  margin-bottom: 0.5%;
}
.fetch-button {
  margin-top: 0.5%;
  margin-bottom: 0.5%;
  margin-left: 0.5%;
  display: inline-block;
  padding: 3px 5px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  color: #fff;
  background-color: rgb(4, 116, 180); /* Green background color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  transition: transform 0.3s; /* Add a smooth transition effect */
}
</style>
