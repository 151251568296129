export default {
    methods: {
        toggleInactiveAssetFunctionDropdown() {
            console.log( this.InactiveAssetFunctionDropdownOpen)
            this.InactiveAssetFunctionDropdownOpen =
              !this.InactiveAssetFunctionDropdownOpen;
              console.log( this.InactiveAssetFunctionDropdownOpen)

          },
          saveChanges() {
            this.InactiveAssetFunctionDropdownOpen = false;
          },
    }
  };
